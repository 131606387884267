// Created By Yash l.

import { createSlice } from "@reduxjs/toolkit";

interface InitialnewsListState {
  newsList: any;
  newsStudentList: any;
  newsDropDown: any;
  tagsList: any;
  setNews_rowCount: number;
  getNewData: any;
  getnewsmainpage: any;
  preview: boolean;
  newsLetArtData: any;
  newsListById: any;
}

const InitialnewsListState: InitialnewsListState = {
  newsList: [],
  newsLetArtData: [],
  newsDropDown: [],
  newsStudentList: [],
  tagsList: [],
  setNews_rowCount: 0,
  getNewData: [],
  getnewsmainpage: [],
  newsListById: [],
  preview: false,
};

const newsListSlice = createSlice({
  name: "newsList",
  initialState: InitialnewsListState,
  reducers: {
    fetchNewsStuList(state: any, action: any) {
      state.newsStudentList = action.payload;
    },
    setUserNewsById(state: any, action: any) {
      state.newsListById = action.payload;
    },
    newsDropdown(state: any, action: any) {
      state.newsDropDown = action.payload;
    },
    fetchNewsList(state: any, action: any) {
      state.newsList = action.payload;
    },
    fetchTagsList(state: any, action: any) {
      state.tagsList = action.payload;
    },
    setNews_rowCount(state: any, action: any) {
      state.news_rowCount = action.payload;
    },
    deleteNews(state: any, action: any) {
      state.newsList = state.newsList.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    getNewsList(state: any, action: any) {
      state.getNewData = action.payload;
    },
    fetchNewsLetArtById(state: any, action: any) {
      state.newsLetArtData = action.payload;
    },
    addnewstitdesc(state: any, action: any) {
      state?.eventmainpage?.push(action.payload)
    },
    getaddnewstitdesc(state: any, action: any) {
      state.getnewsmainpage = action.payload;
    },
    setPreview(state, action) {
      state.preview = action.payload;
    },
  },
});

export const {
  fetchNewsStuList,
  newsDropdown,
  setNews_rowCount,
  fetchNewsList,
  deleteNews,
  fetchTagsList,
  getNewsList,
  fetchNewsLetArtById,
  addnewstitdesc,
  getaddnewstitdesc,
  setPreview,
  setUserNewsById
} = newsListSlice.actions;

export default newsListSlice.reducer;

export const newslistStu = (state: any) => state.newsList.newsStudentList;

export const newsDropDown = (state: any) => state.newsList.newsDropDown;

export const newsLetArtList = (state: any) => state.newsList.newsLetArtData;

export const news_list = (state: any) => state.newsList.newsList;

export const news_rowCount = (state: any) => state.newsList.news_rowCount;

export const tagsList = (state: any) => state.newsList.tagsList;

export const getNews_List = (state: any) => state.newsList.getNewData;

export const getNewsByIds = (state: any) => state.newsList.newsListById;