// Created By RS

import { createSlice } from "@reduxjs/toolkit";

interface InitialFAQsState {
  question: any;
  memberFaq: any[];
  rowCount: number;
  isLoading: boolean;
  questionById: any;
  filterdata:any;
}
const initialFAQsState: InitialFAQsState = {
  question: [],
  memberFaq: [],
  rowCount: 0,
  isLoading: false,
  questionById: {},
  filterdata:{},
};

const FAQsSlice = createSlice({
  name: "faqs",
  initialState: initialFAQsState,
  reducers: {
    fetchFAQs(state: any, action: any) {
      state.question = action.payload;
    },
    fetchMemberFAQs(state: any, action: any) {
      state.memberFaq = action.payload;
    },
    addQuestion(state: any, action: any) {
      state?.question?.push(action.payload);
    },
    addQuestionCopy(state: any, action: any) {
      state?.question?.push(action.payload);
    },
    setRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    editQuestion(state: any, action: any) {
      const objIndex = state.question.findIndex(
        (obj: any) => obj._id === action.payload?.id,
      );

      if (objIndex !== -1) {
        state.question[objIndex] = {
          ...state.question[objIndex],
          ...action.payload,
        };
      }
    },
    // editQuestion(state: any, action: any) {
    //   const userId = action.payload?.id;
    //   if (userId && state.question.hasOwnProperty(userId)) {
    //     state.question[userId] = {
    //       ...state.question[userId],
    //       ...action.payload,
    //     };
    //   }
    // },
    deleteQuestion(state: any, action: any) {
      state.question = state.question.filter(
        (obj: any) => obj._id !== action?.payload?.id
      );
    },
    changeLoadingState(state: any, action: any) {
      state.isLoading = action.payload.loading;
    },

    setQuestionById(state: any, action: any) {
      state.questionById = action.payload;
    },
    clearFAQs: (state: any) => {
      state.questionById = {};
    },
    setPassData(state: any, action: any) {
      state.filterdata = action.payload;
    },
  },
});

export const {
  fetchFAQs,
  fetchMemberFAQs,
  addQuestion,
  addQuestionCopy,
  editQuestion,
  deleteQuestion,
  setRowCount,
  changeLoadingState,
  setQuestionById,
  clearFAQs,
} = FAQsSlice.actions;

export default FAQsSlice.reducer;

export const faqsData = (state: any) => state.faqs.question;

export const memberFaqsData = (state: any) => state.faqs.memberFaq;

export const rowCount = (state: any) => state.faqs.rowCount;

export const question_By_Id = (state: any) => state.faqs.questionById;

export const filter_data = (state: any) => state.faqs.filterdata;

