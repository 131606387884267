// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialPremotionState {
  promotions: any;
  isLoading: boolean;
  rowCount: number;
  promotionsId: any;
}
const initialPremotionState: InitialPremotionState = {
  promotions: [],
  isLoading: false,
  rowCount: 0,
  promotionsId: {},
};

const PromotionSlice = createSlice({
  name: "promotions",
  initialState: initialPremotionState,
  reducers: {
    promotionList(state: any, action: any) {
      state.promotions = action.payload;
    },
    setPromotionCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    addCoupon(state: any, action: any) {
      state.promotions.push(action.payload);
    },
    editCoupon(state: any, action: any) {
      const objIndex = state.promotions.findIndex(
        (obj: any) => obj._id === action.payload?.id
      );

      if (objIndex !== -1) {
        state.promotions[objIndex] = {
          ...state.promotions[objIndex],
          ...action.payload,
        };
      }
    },
    deleteCoupon(state: any, action: any) {
      state.promotions = state.promotions.filter(
        (obj: any) => obj._id !== action?.payload?.id
      );
    },
    setPromotionById(state: any, action: any) {
      state.promotionsId = action.payload;
    },
  },
});

export const {
  promotionList,
  addCoupon,
  editCoupon,
  deleteCoupon,
  setPromotionCount,
  setPromotionById
} = PromotionSlice.actions;

export default PromotionSlice.reducer;

export const promotionsData = (state: any) => state.promotions.promotions;
export const promotions_Count = (state: any) => state.promotions.rowCount;
export const promotionById = (state: any) => state.promotions.promotionsId;

