// Created By MK

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  certificate: any;
  certificateCount: any;
  currentChats: any;
}
const initialUsersState: InitialUsersState = {
  certificate: [],
  certificateCount: [],
  currentChats: []
};

const CertificateApprovalSlice = createSlice({
  name: "certificateApproval",
  initialState: initialUsersState,
  reducers: {
    fetchCertificate(state: any, action: any) {
      state.certificate = action.payload;
    },
    certificatesApprovedCount(state: any, action: any) {
      state.certificateCount = action.payload;
    },
    removeCertificate(state: any, action: any) {
      state.certificate = state.certificate?.filter((val: any) => val?._id !== action?.payload)
    },
    fetchCurrentChats(state: any, action: any) {
      state.currentChats = action.payload;
    },
    addChat(state: any, action: any) {
      state?.currentChats?.push(action.payload)
    },
    deleteChat(state: any, action: any) {
      state.currentChats = state?.currentChats?.filter((chat: any) => chat?._id !== action?.payload)
    },
  },
});

export const {
  fetchCertificate,
  certificatesApprovedCount,
  fetchCurrentChats,
  removeCertificate,
  addChat,
  deleteChat
}: any = CertificateApprovalSlice.actions;

export default CertificateApprovalSlice.reducer;

export const certificateData = (state: any) => state.certificateApproval.certificate

export const certificateCount = (state: any) => state.certificateApproval.certificateCount

export const currentChatsData = (state: any) => state.certificateApproval.currentChats