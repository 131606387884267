// React
import { useCallback, useEffect, useState } from "react";

// Bootstrap
import { Col, Row, Modal, ModalBody } from "reactstrap";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { schoolById, schoolList } from "../../../../../ReduxToolkit/Reducer/AssociatedSchool/AssociatedSchoolSlice";
import { fetchSchoolList, schoolFindById } from "../../../../../ReduxToolkit/Reducer/AssociatedSchool/AssociatedSchoolAction";
import { getWebSettingsHomeLists } from "../../../../../ReduxToolkit/Reducer/HomePage/HomePageAction";
import { web_list } from "../../../../../ReduxToolkit/Reducer/HomePage/HomePageSlice";

// Custom Component
import Header from "../../Header/Header";
import Footer from "../../../Footer/Footer.component";

// Helpers
import UrlHelper from "../../../../../Helpers/Url";
import ImageHelper from "../../../../../Helpers/Images/Image";

// Third Party 
import { FeatherIcons, H2 } from "../../../../../AbstractElements";

// Style
import "./SchoolViewMore.scss";

interface Location {
  name: string;
  isoCode?: string;
  countryCode?: string;
}

export default function SchoolViewMore() {
  // Hooks
  const [openModal, setOpenModal] = useState(false);

  // Hooks
  const school = useSelector(schoolList);
  const school_ById = useSelector(schoolById);
  const dispatch = useDispatch();
  const wedData = useSelector(web_list)

  // Color Array
  const schoolColors = [
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(246, 235, 15) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(90, 188, 119)91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(56, 83, 163) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(236, 30, 36) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(90, 188, 119) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(241, 198, 68) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(236, 30, 36) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(90, 188, 119) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(246, 235, 15) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(56, 83, 163) 91%)",
    "linear-gradient(rgb(15, 18, 33) 0%, rgb(15, 18, 33) -0.3%, rgb(2, 0, 0) 95.15%, rgb(56, 83, 163) 91%)"
  ];

  // Convert Json Data City || Country || State
  const parseLocation = (jsonString: string): Location | undefined => {
    try {
      return JSON.parse(jsonString) as Location;
    } catch (error) {

      return undefined;
    }
  };

  const country = parseLocation(school_ById.country);
  const state = parseLocation(school_ById.state);
  const city = parseLocation(school_ById.city);

  // Data Fetch Api call
  const fetchListData = useCallback(async () => {
    await fetchSchoolList(
      { startToken: 0, endToken: 11, search: "" },
      dispatch
    );
    await getWebSettingsHomeLists({}, dispatch);
  }, []);

  useEffect(() => {
    fetchListData();
  }, []);

  const handleEventInfo = async (schoolId: any) => {
    const res = await schoolFindById({ id: schoolId }, dispatch);
    if (res?.status) {
      setOpenModal(true);
    }
  };

  return (
    <div>
      <Header />
      <div className="schoolmainpage">
        <p className="schoolparCont">
          {wedData.associated_title}
        </p>
        <p className="schoolsubCont">
          {wedData.associated_description}
        </p>
      </div>
      <div
        style={{ backgroundColor: "rgba(19, 33, 59, 1)" }}
        className="schoolRes"
      >
        <Row>
          {school && school?.length > 0 ? (
            school?.map((item: any, index: any) => (
              <Col sm={12} md={6} lg={6} key={index}>
                <div className="schoolnameBox">
                  <div
                    className="schooltextBox"
                    style={{
                      background: schoolColors[index % schoolColors.length],
                    }}
                    onClick={() => handleEventInfo(item?._id)}
                  >
                    <div className="schoolNametit">
                      <p className="schoolName">{item.name}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="noDataPlceholder">
              <img
                src={ImageHelper.ISSOLogoPlaceholder}
                alt="MemshipOverviewImg"
                style={{
                  width: "100%",
                  height: "calc(30vh)",
                  objectFit: "contain",
                  zIndex: "1 !important",
                }}
              />
            </div>
          )}
        </Row>
      </div>
      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(!openModal)}
        centered
        id="schoolview"
      >
        <ModalBody className="schooldetails-modalbody">
          <div className="schoolimage">
            <img src={school_ById.logo ? `${UrlHelper.serverImageUrl}${school_ById.logo}` : ImageHelper.ISSOLogoPlaceholder} alt="mail" />
          </div>
          <div className="schoolConRight">
            <div className="header_con">
              <H2 className="header_title">School Info</H2>
              <FeatherIcons onClick={() => setOpenModal(false)} iconName={"X"} className="text-black close-icon" />
            </div>
            <table
              className="table"
              style={{
                width: "880px !important",
              }}
            >
              <tbody>
                <tr>
                  <td className="left-upcetitle">School Name:</td>
                  <td className="right-upcetitle">
                    {school_ById?.name}
                  </td>
                </tr>

                <tr>
                  <td className="left-upcetitle">Email Address:</td>
                  <td className="right-upcetitle">
                    {school_ById?.email}
                  </td>
                </tr>

                <tr>
                  <td className="left-upcetitle">Mobile Number:</td>
                  <td className="right-upcetitle">
                    {school_ById?.mobile}
                  </td>
                </tr>

                <tr>
                  <td className="left-upcetitle">Principal Name:</td>
                  <td className="right-upcetitle">
                    {school_ById?.principle_name}
                  </td>
                </tr>

                <tr>
                  <td className="left-upcetitle">Address:</td>
                  <td style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    borderBottom: "none",
                    margin: "12px 0px",
                    padding: "0px 12px"
                  }}>
                    {school_ById?.address}
                  </td>
                </tr>

                <tr>
                  <td className="left-upcetitle">Country:</td>
                  <td className="right-upcetitle">
                    {country?.name || 'N/A'}
                  </td>
                </tr>

                <tr>
                  <td className="left-upcetitle">State:</td>
                  <td className="right-upcetitle">
                    {state?.name || 'N/A'}
                  </td>
                </tr>


                <tr>
                  <td className="left-upcetitle">City:</td>
                  <td className="right-upcetitle">
                    {city?.name || 'N/A'}
                  </td>
                </tr>

                <tr>
                  <td className="left-upcetitle">Zip Code:</td>
                  <td className="right-upcetitle">
                    {school_ById?.zip_code}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              className="form-footer"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: '0.5rem',
                marginTop: "2rem",
                paddingLeft: '0.7rem',
              }}
            >
              {/* <button
                    type="button"
                    onClick={() => setOpenModal(!openModal)}
                    className="upcevent_close_btn"
                  >
                    Close
                  </button> */}
              {/* {(
                    (selectedEvent?.registration_end_date < selectedEvent?.start_date && selectedEvent?.status === 1) ||
                    !selectedEvent?.is_registered
                  ) ? (
                    <button
                      className="upcevent_reg_btn"
                      // onClick={() => {
                      //   if (AppUtils.getLocalStorage("ISSO")) {
                      //     if (!selectedEvent?.is_free) {
                      //       setOpenpaymemtModal(true);
                      //       setOpenModal(false);
                      //     } else {
                      //       userRegistered();
                      //     }
                      //   } else {
                      //     AppUtils.setLocalStorage("previousPage", previousPage);
                      //     navigate("/login");
                      //   }
                      // }}
                    >
                      Register
                    </button>
                  ) : ( */}
              {/* <button type="submit" color="primary" className="upcevent_reg_btn">
                      Join
                    </button> */}
              {/* )} */}
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Footer />
    </div >
  );
}
