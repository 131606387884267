import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  sportType: any;
  isLoading: boolean;
  sportById: any;
  rowCount: number;
}
const initialUsersState: InitialUsersState = {
  sportType: [],
  isLoading: false,
  sportById: {},
  rowCount: 0,
};

const SportTypeSlice = createSlice({
  name: "sportType",
  initialState: initialUsersState,
  reducers: { 
    fetchSportTypes(state: any, action: any) {
      state.sportType = action.payload;
    },
    setSportCount(state: any, action: any) {
      state.rowCount = action.payload
    },
    addSportType(state: any, action: any) {
      state?.sportType?.push(action.payload);
    },
    editSportType(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.sportType.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.sportType[objIndex] = {
          ...state.sportType[objIndex],
          ...updatedData,
        };
      }
    },
    deleteSportType(state: any, action: any) {
      state.sportType = state.sportType.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setSportTypeById(state: any, action: any) {
      state.sportById = action.payload;
    },
    clearSportTypes: (state: any) => {
      state.sportById = {};
    },
  },
});

export const {
  fetchSportTypes,
  addSportType,
  editSportType,
  deleteSportType,
  setSportTypeById,
  clearSportTypes,
  setSportCount
} = SportTypeSlice.actions;

export default SportTypeSlice.reducer;

export const sportType_list = (state: any) => state.sportType.sportType;

export const sportById = (state: any) => state.sportType.sportById;

export const sport_Count = (state: any) => state.sportType.rowCount;
