import { createSlice } from "@reduxjs/toolkit";

interface InitialHomeCommunityState {
  community: any;
  replay: any;
  setTopic_rowCount: any;
}
const communityState: InitialHomeCommunityState = {
  community: [],
  replay: [],
  setTopic_rowCount: 0,
};
const CommunityHomeSlice = createSlice({
  name: "community",
  initialState: communityState,
  reducers: {
    fetchTopicList(state: any, action: any) {
      state.community = action.payload;
    },
    setMember_Count(state: any, action: any) {
      state.setTopic_rowCount = action.payload;
    },
    addComments(state: any, action: any) {
      state?.community?.push(action.payload);
    },
    deleteComment(state: any, action: any) {
      state.community = state.community.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    editComment(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.community.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.community[objIndex] = {
          ...state.community[objIndex],
          ...updatedData,
        };
      }
    },
    addReply(state: any, action: any) {
      state?.replay?.push(action.payload);
    },
    editReply(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.replay.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.replay[objIndex] = {
          ...state.replay[objIndex],
          ...updatedData,
        };
      }
    },
    deleteReply(state: any, action: any) {
      state.community = state.community.filter(
        (item: any) => item._id !== action?.payload
      );
    },
  },
});

export const { fetchTopicList, addComments, setMember_Count, editComment, deleteReply, addReply, editReply, deleteComment } = CommunityHomeSlice.actions;
export default CommunityHomeSlice.reducer;

export const communityType_list = (state: any) => state.community.community;

export const selectCommentList = (state: any) => state.community.community;

export const Member_Count = (state: any) => state.community.setTopic_rowCount;

