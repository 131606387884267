// Created By Dhruvi V.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  VirtualChallangemainpage: any;
  virtualList: any;
  virtual: any;
}

const InitialUsersState: InitialUsersState = {
  VirtualChallangemainpage: [],
  virtualList: [],
  virtual: [],
};
const VirtualSlice = createSlice({
  name: "virtual",
  initialState: InitialUsersState,
  reducers: {
    addVirtualmainpage(state: any, action: any) {
      state?.virtualmainpage?.push(action.payload);
    },
    addVirtual(state: any, action: any) {
      state?.virtual?.push(action.payload);
    },
    editVirtual(state: any, action: any) {
      state.virtualList.virtual = state.virtualList.virtual.map((item: any) => {
        if (item._id === action.payload?.id) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },
    deleteVirtual(state: any, action: any) {
      const updatedData = state.virtualList.virtual.filter((item: any) => item._id !== action.payload);
      state.virtualList.virtual = updatedData;
    },
    fetchVirtualChallangeList(state: any, action: any) {
      state.virtualList = action.payload;
    },
    getVirtualChallanges(state: any, action: any) {
      state.virtual = action.payload;
    },
    clearVirtual: (state: any) => {
      state.VirtualById = {};
    },
    addVirtualChallengesmainpage(state: any, action: any) {
      state?.virtual?.push(action.payload);
    },
    editGuideLine(state: any, action: any) {
      state.virtualList.guideline = state.virtualList.guideline.map((item: any) => {
        if (item._id === action.payload?.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
    },
    deleteGuideLine(state: any, action: any) {
      const updatedData = state.virtualList.guideline.filter((item: any) => item._id !== action.payload);
      state.virtualList.guideline = updatedData;
    },
    fetchVirtual(state: any, action: any) {
      state.virtualList.virtual = action.payload;
    },
    fetchGuide(state: any, action: any) {
      state.virtualList.guideline = action.payload;
    },
  },
});
export const {
  fetchVirtualChallangeList,
  addVirtual,
  editVirtual,
  deleteVirtual,
  clearVirtual,
  addVirtualmainpage,
  getVirtualChallanges,
  addVirtualChallengesmainpage,
  addGuideLine,
  editGuideLine,
  deleteGuideLine,
  fetchGuide,
  fetchVirtual,
}: any = VirtualSlice.actions;
export default VirtualSlice.reducer;

export const VirtualChallanges_list = (state: any) => state.virtual.virtual;

export const VirtualDataList = (state: any) => state.virtual.virtualList;

export const virtualChallanges_Homepage = (state: any) => state.virtual.virtual;
