// Created By MK

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
    eventmainpage: any;
    eventReg: any;
    eventSeo: any;
    eventRegById: any;
    eventRegList: any;
}
const initialUsersState: InitialUsersState = {
    eventmainpage: [],
    eventReg: [],
    eventSeo: [],
    eventRegById: {},
    eventRegList: [],
};

const EventRegSlice = createSlice({
    name: "eventReg",
    initialState: initialUsersState,
    reducers: {
        fetchEventRegs(state: any, action: any) {
            state.eventReg = action.payload;
        },
        fetchEventSeo(state: any, action: any) {
            state.eventSeo = action.payload;
        },
        addEventmainpage(state: any, action: any) {
            state?.eventmainpage?.push(action.payload)
        },
        addEventReg(state: any, action: any) {
            state?.eventReg?.push(action.payload)
        },
        editEventReg(state: any, action: any) {
            state.eventReg = state.eventReg.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deleteEventReg(state: any, action: any) {
            state.eventReg = state.eventReg.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        setEventRegById(state: any, action: any) {
            state.eventRegById = action.payload;
        },
        clearEventReg: (state: any) => {
            state.eventRegById = {};
        },
        geteventHomePage(state: any, action: any) {
            state.eventReg = action.payload;
        },
        geteventList(state: any, action: any) {
            state.eventRegList = action.payload;
        },
    },
});

export const {
    fetchEventRegs,
    fetchEventSeo,
    addEventmainpage,
    addEventReg,
    editEventReg,
    deleteEventReg,
    setEventRegById,
    clearEventReg,
    toggleEditEventreg,
    geteventHomePage,
    geteventList,
}: any = EventRegSlice.actions;

export default EventRegSlice.reducer;

export const eventReg_list = (state: any) => state.eventReg.eventReg;

export const eventSeo_list = (state: any) => state.eventReg.eventSeo;

export const eventRegById = (state: any) => state.eventReg.eventRegById;

export const eventReg_Homepage = (state: any) => state.eventReg.eventReg;

export const eventReg_Homepage_List = (state: any) => state.eventReg.eventRegList;


