// Created By Raj

import { createSlice } from "@reduxjs/toolkit";

interface initialMemberState {
    membershipPage: any;
    whyjoin: any;
    whyjoinByid: any;
}
const initialMemberState: initialMemberState = {
    membershipPage: [],
    whyjoin: [],
    whyjoinByid: {}
};

const MembershipSlice = createSlice({
    name: "memberShip",
    initialState: initialMemberState,
    reducers: {
        addmemberShipData(state: any, action: any) {
            state?.membershipPage?.push(action.payload)
        },
        addmemberProcessData(state: any, action: any) {
            state?.membershipPage?.process_steps?.push(action.payload)
        },
        getMemberShip(state: any, action: any) {
            state.membershipPage = action.payload;
        },
        fetchWhyJoin(state: any, action: any) {
            state.whyjoin = action.payload;
        },
        setwhyJoinById(state: any, action: any) {
            state.whyjoinByid = action.payload;
        },
        addWhyJionisso(state: any, action: any) {
            state?.whyjoin?.push(action.payload)
        },
        editWhyJoin(state: any, action: any) {
            state.whyjoin = state.whyjoin.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        editMemberProcess(state: any, action: any) {
            state.membershipPage.process_steps = state?.membershipPage?.process_steps?.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deleteWhyJoin(state: any, action: any) {
            state.whyjoin = state.whyjoin.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        deleteMemberProcess(state: any, action: any) {
            const updatedProcesssteps = state?.membershipPage?.process_steps.filter((item: any) => item._id !== action.payload);
            state.membershipPage.process_steps = updatedProcesssteps;
        },
    },
});

export const {
    addmemberShipData,
    addmemberProcessData,
    getMemberShip,
    fetchWhyJoin,
    setwhyJoinById,
    editWhyJoin,
    editMemberProcess,
    deleteWhyJoin,
    deleteMemberProcess,
    addWhyJionisso,
}: any = MembershipSlice.actions;

export default MembershipSlice.reducer;

export const webMem_list = (state: any) => state.memberShip.membershipPage;
export const join_list = (state: any) => state.memberShip.whyjoin;
export const whyjoinByid = (state: any) => state.memberShip.whyjoinByid;
