// Created By RS

import { createSlice } from "@reduxjs/toolkit";

interface InitialBadgesState {
  badge: any;
  rowCount: number;
  isLoading: boolean;
}
const initialBadgesState: InitialBadgesState = {
  badge: [],
  rowCount: 0,
  isLoading: false,
};

const BadgeSlice = createSlice({
  name: "badges",
  initialState: initialBadgesState,
  reducers: {
    fetchBadges(state: any, action: any) {
      state.badge = action.payload;
    },
    setBadageRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
  },
});

export const { fetchBadges, setBadageRowCount } = BadgeSlice.actions;

export default BadgeSlice.reducer;

export const badgeData = (state: any) => state.badges.badge;

export const rowCount = (state: any) => state.badges.rowCount;
