// Created By MK

// React Imports

import React, { Suspense } from "react";

//CustomComponents
import Spinner from "../Component/CustSpinner/Customspinner.component";

const Error400 = React.lazy(
  () => import("../Pages/OtherPages/Error/Error400/Error400")
);
const Error401 = React.lazy(
  () => import("../Pages/OtherPages/Error/Error401/Error401")
);
const Error403 = React.lazy(
  () => import("../Pages/OtherPages/Error/Error403/Error403")
);
const Error404 = React.lazy(
  () => import("../Pages/OtherPages/Error/Error404/Error404")
);
const Error500 = React.lazy(
  () => import("../Pages/OtherPages/Error/Error500/Error500")
);
const Error503 = React.lazy(
  () => import("../Pages/OtherPages/Error/Error503/Error503")
);

export const authRoutes = [
  {
    path: "/errors/error400",
    component: (
      <Suspense fallback={<Spinner />}>
        <Error400 />
      </Suspense>
    ),
  },
  {
    path: "/errors/error401",
    component: (
      <Suspense fallback={<Spinner />}>
        <Error401 />
      </Suspense>
    ),
  },
  {
    path: "/errors/error403",
    component: (
      <Suspense fallback={<Spinner />}>
        <Error403 />
      </Suspense>
    ),
  },
  {
    path: "/errors/error404",
    component: (
      <Suspense fallback={<Spinner />}>
        <Error404 />
      </Suspense>
    ),
  },
  {
    path: "/errors/error500",
    component: (
      <Suspense fallback={<Spinner />}>
        <Error500 />
      </Suspense>
    ),
  },
  {
    path: "/errors/error503",
    component: (
      <Suspense fallback={<Spinner />}>
        <Error503 />
      </Suspense>
    ),
  },
];
