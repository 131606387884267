import { io } from "socket.io-client";
import AppUtils from "../Helpers/AppUtils";

const socket = io("https://isso.tjcg.in", {
  path: "/socket.io",
  query: {
    userId: AppUtils.getUserDtls()?.data?.id ?? "",
  },
  transports: ["websocket"],
});

socket.on("connect", () => {
  console.log("Connected to server");
});

socket.on("disconnect", () => {
  console.log("Disconnected from server");
});

socket.on("connect_error", (err: any) => {
  console.error("Connection error:", err);
});

export default socket;
