import { createSlice } from "@reduxjs/toolkit";

interface InitialFeedbackState {
    paymentList: any;
    rowCount: number;
}

const InitialPaymentkState: InitialFeedbackState = {
    paymentList: [],
    rowCount: 0,
};

const paymentSlice = createSlice({
    name: "payment",
    initialState: InitialPaymentkState,
    reducers: {
        paymentDataList(state: any, action: any) {
            state.paymentList = action.payload;
        },
        setPaymentlistCount(state: any, action: any) {
            state.rowCount = action.payload
        },
    },
});

export const {
    paymentDataList,
    setPaymentlistCount
} = paymentSlice.actions;

export default paymentSlice.reducer;

export const payment_List = (state: any) => state.payment.paymentList;

export const payment_Count = (state: any) => state.payment.rowCount;