// Created By Yash l.

// Redux
import { createSlice } from "@reduxjs/toolkit";

interface InitialTandCState {
    privacyPolicy: any;
    getPrivacyPolicy: any;
}

const InitialPrivacyPolicy: InitialTandCState = {
    privacyPolicy: [],
    getPrivacyPolicy: [],
};

const privacyPolicySlice = createSlice({
    name: "privacyPolicy",
    initialState: InitialPrivacyPolicy,
    reducers: {
        privacyPolicy(state: any, action: any) {
            state?.privacyPolicy?.push(action.payload);
            state.total = Number(state.total) + 1;
        },
        getPrivacyPolicy(state: any, action: any) {
            state.getPrivacyPolicy = action.payload;
        },
    },
});

export const { privacyPolicy, getPrivacyPolicy } = privacyPolicySlice.actions;

export default privacyPolicySlice.reducer;

export const privacyPol = (state: any) => state.privacyPolicy.privacyPolicy;

export const privacyPolData = (state: any) => state.privacyPolicy.getPrivacyPolicy;
