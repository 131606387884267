// Created By Dhruvi V.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  Testimonialmainpage: any;
  testimonial: any;
  testimonialSEO: any;
  testimonialById: any;
  TestimonilListData: any;
}

const InitialUsersState: InitialUsersState = {
  Testimonialmainpage: [],
  testimonial: [],
  testimonialSEO: [],
  testimonialById: {},
  TestimonilListData: [],
};

const TestimonialSlice = createSlice({
  name: "testimonial",
  initialState: InitialUsersState,
  reducers: {
    fetchTestimonial(state: any, action: any) {
      state.testimonial = action.payload;
    },
    fetchTestimonialSEO(state: any, action: any) {
      state.testimonialSEO = action.payload;
    },
    addTestimonial(state: any, action: any) {
      state?.testimonial?.push(action.payload);
    },
    editTestimonial(state: any, action: any) {
      state.testimonial = state.testimonial.map((item: any) => {
        if (item._id === action.payload?.id) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    },
    deleteTestimonial(state: any, action: any) {
      state.testimonial = state.testimonial.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setTestimonialById(state: any, action: any) {
      state.testimonialById = action.payload;
    },
    clearTestimonial: (state: any) => {
      state.testimonialById = {};
    },
    addTestimonialmainpage(state: any, action: any) {
      state?.testimonialmainpage?.push(action.payload);
    },
  },
});

export const {
  fetchTestimonial,
  fetchTestimonialSEO,
  addTestimonialmainpage,
  addTestimonial,
  editTestimonial,
  deleteTestimonial,
  setTestimonialById,
  clearTestimonial,
}: any = TestimonialSlice.actions;
export default TestimonialSlice.reducer;

export const testionial_list = (state: any) => state.testimonial.testimonial;

export const testionialSEO_list = (state: any) => state.testimonial.testimonialSEO;

export const testimonialById = (state: any) => state.testimonial.testimonialById;
