// Created By Yash l.

import { createSlice } from "@reduxjs/toolkit";

interface initialPrefixState {
  addPrefix: any;
  prefixList: any;
}

const initialPrefixState: initialPrefixState = {
  addPrefix: [],
  prefixList: [],
};

const PrefixManSlice = createSlice({
  name: "prefix",
  initialState: initialPrefixState,
  reducers: {
    prefixList(state: any, action: any) {
      state.addPrefix = action.payload;
    },
    prefixAllLists(state: any, action: any) {
      state.prefixList = action.payload;
    },
    addPremotions(state: any, action: any) {
      state.addPrefix.push(action.payload);
    },
  },
});

export const {
  addPremotions,
  prefixList,
  prefixAllLists,
} = PrefixManSlice.actions;

export default PrefixManSlice.reducer;

export const prefixData = (state: any) => state.prefix.addPrefix;

export const prefixDataList = (state: any) => state.prefix.prefixList;