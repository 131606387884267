// Created By DV

import { createSlice } from "@reduxjs/toolkit";

interface InitialCommunityState {
  community: any;
  CommunityType: any;
  SetType_rowCount: number;
  SetTopic_rowCount: number;
  communitytypeById: any;
  getNewData: any;
  HomePagecommunity: any;
}
const communityState: InitialCommunityState = {
  community: [],
  CommunityType: [],
  SetType_rowCount: 0,
  SetTopic_rowCount: 0,
  communitytypeById: {},
  getNewData: [],
  HomePagecommunity: [],
};
const CommunitySlice = createSlice({
  name: "community",
  initialState: communityState,
  reducers: {
    fetchCommunityTypeList(state: any, action: any) {
      state.CommunityType = action.payload;
    },
    addCommunityType(state: any, action: any) {
      state.CommunityType = action.payload;
    },
    editCommunityType(state: any, action: any) {
      state.CommunityType = action.payload;
    },
    deleteCommunityType(state: any, action: any) {
      state.CommunityType = state.CommunityType.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    fetchTopicList(state: any, action: any) {
      state.community = action.payload;
    },
    setHasMore(state: any) {
      const data = false;
      state.hasMore = data;
    },
    setType_rowCount(state: any, action: any) {
      state.SetType_rowCount = action.payload;
    },
    setTopic_rowCount(state: any, action: any) {
      state.setTopic_rowCount = action.payload;
    },
    setMember_Count(state: any, action: any) {
      state.setTopic_rowCount = action.payload;
    },
    deleteTopic(state: any, action: any) {
      state.community = state.community.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    addTopic(state: any, action: any) {
      state?.community?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    editTopic(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.community.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.community[objIndex] = {
          ...state.community[objIndex],
          ...updatedData,
        };
      }
    },
    getTopicList(state: any, action: any) {
      state.geTopicData = action.payload;
    },
    setCommunitytypeById(state: any, action: any) {
      state.communitytypeById = action.payload;
    },
  },
});

export const {
  fetchCommunityTypeList,
  fetchTopicList,
  setType_rowCount,
  setTopic_rowCount,
  setMember_Count,
  addTopic,
  deleteTopic,
  editTopic,
  setCommunitytypeById,
  addCommunityType,
  editCommunityType,
  deleteCommunityType,
} = CommunitySlice.actions;
export default CommunitySlice.reducer;

export const topic_list = (state: any) => state.community.CommunityType;

export const communityType_list = (state: any) => state.community.community;

export const SetType_rowCount = (state: any) =>
  state.community.SetType_rowCount;

export const CommunitytypeById = (state: any) =>
  state.community.communitytypeById;

export const topic_rowCount = (state: any) => state.community.setTopic_rowCount;

export const Member_Count = (state: any) => state.community.setTopic_rowCount;
