import { createSlice } from "@reduxjs/toolkit";

interface InitialInquiryState {
    inquiry: any;
}

const InitialInquiryState: InitialInquiryState = {
    inquiry: [],
};

const inquirySlice = createSlice({
    name: "inquiry",
    initialState: InitialInquiryState,
    reducers: {
        addInquiry(state: any, action: any) {
            state.inquiry = action.payload;
        },
    },
});

export const {
    addInquiry,
} = inquirySlice.actions;

export default inquirySlice.reducer;