const Consthelper = {
  emailRegex:
    /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/i,
  spaceRegex: /^\s/,
  passwordMatchRegex: /[^a-zA-Z0-9@!#$&*>]/g,
  numberRegex: /^(?!(0))[0-9]+$/,
  mobileNoRegex: /^(?!0+$)[0-9\(\)-]{10,}$/,
  alphabetAndNumRegex: /[^a-zA-Z\s]/g,
  noRegex: /[^0-9]/g,
  cardHolderNameRegex: /^(?![0-9]*$)[a-zA-Z0-9]+[ ]{0,1}[a-zA-Z0-9 ]*$/,
  nameRegex: /^[a-zA-Z]+[ ]{0,1}[a-zA-Z ]*$/,
  priceRegex: /^\d{7}$/g,
  linkRegex: /(https?:\/\/[^\s]+)/g,
  youtubeRegex: /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/,
  removeTagRegex: /<[^>]+>/g,
  textRegex: /[\r\n]/gm,
  onlyNumberRegex: /\D/g,
  passRegx: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
  allKeyRegex: /[\s\S]/,
  discountRegex: /^(?!00)(\d{0,2}(\.(?!00)\d{0,2})?)?$/,
  formEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  formMobile: /^\(\d{3}\)-\d{3}-\d{4}$/,
  formPassword: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
  Zip: /^[A-Z\d]{0,6}$/,
  Zip_Code: /[^A-Z0-9]/g,
};

export default Consthelper;
