// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  workshop: any;
  rowCount: number;
  workshopById: any;
  hasMore: boolean;
  userList: any[];
  userListRecordCount: number;
  spWorkshopRecordCount: number;
  spUserWorkshop: any[];
  workshopCount: any;
  workshopStatusCount: any;
}
const initialUsersState: InitialUsersState = {
  workshop: [],
  workshopCount: [],
  rowCount: 0,
  workshopById: {},
  hasMore: true,
  userList: [],
  userListRecordCount: 0,
  spWorkshopRecordCount: 0,
  spUserWorkshop: [],
  workshopStatusCount: [],
};

const WorkShopSlice = createSlice({
  name: "workshop",
  initialState: initialUsersState,
  reducers: {
    fetchWorkshop(state: any, action: any) {
      const { isSearch } = action.payload;
      if (isSearch) {
        state.workshop = action.payload.data;
      } else {
        state.workshop = action.payload.data;
      }
    },
    fetchWorkshopCal(state: any, action: any) {
      state.workshop = action.payload;
    },
    fetchWorkshopStatus(state: any, action: any) {
      state.workshopStatusCount = action.payload;
    },
    WorkshopCounting(state: any, action: any) {
      state.workshopCount = action.payload;
    },
    setRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    addWorkshop(state: any, action: any) {
      state?.workshop?.unshift(action.payload);
    },
    editWorkshop(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.workshop.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.workshop[objIndex] = {
          ...state.workshop[objIndex],
          ...updatedData,
        };
      }
    },
    setworkshopById(state: any, action: any) {
      state.workshopById = action.payload;
    },
    deleteWorkshop(state: any, action: any) {
      state.workshop = state.workshop.filter(
        (item: any) => item._id !== action?.payload?.id
      );
    },
    setHasMore(state: any, action: any) {
      state.hasMore = action.payload;
    },
    clearWorkshop(state: any) {
      state.workshop = [];
    },
    cancelWorkshop(state: any, action: any) {
      state.workshop = state.workshop.map((item: any) =>
        item._id === action?.payload.id
          ? { ...item, status: action?.payload.status }
          : item
      );
    },
    fetchUserList(state: any, action: any) {
      state.userList = action.payload;
    },
    setListCount(state: any, action: any) {
      state.userListRecordCount = action.payload;
    },
    fetchSPUserWorkshop(state: any, action: any) {
      const { isSearch } = action.payload;
      if (isSearch) {
        state.spUserWorkshop = action.payload.data;
      } else {
        state.spUserWorkshop = action.payload.data;
      }
    },
    setSPRecordCount(state: any, action: any) {
      state.spWorkshopRecordCount = action.payload;
    },
    updateSPWorkShop(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.spUserWorkshop.findIndex(
        (obj: any) => obj._id === updatedData?._id
      );

      if (objIndex !== -1) {
        state.spUserWorkshop[objIndex] = {
          ...state.spUserWorkshop[objIndex],
          ...updatedData,
        };
      }
    },
    workshopListsbyday(state: any, action: any) {
      state.workshop = action.payload;
    },
    workshopDetails(state: any, action: any) {
      state.workshop = action.payload;
    },
  },
});

export const {
  clearWorkshop,
  WorkshopCounting,
  setHasMore,
  fetchWorkshop,
  setRowCount,
  fetchWorkshopCal,
  addWorkshop,
  editWorkshop,
  setworkshopById,
  deleteWorkshop,
  cancelWorkshop,
  fetchUserList,
  setListCount,
  fetchSPUserWorkshop,
  fetchWorkshopStatus,
  setSPRecordCount,
  updateSPWorkShop,
  workshopListsbyday,
  workshopDetails
}: any = WorkShopSlice.actions;

export default WorkShopSlice.reducer;

export const workshopList = (state: any) => state.workshop.workshop;

export const workshopCountsList = (state: any) => state.workshop.workshopCount;

export const rowCount = (state: any) => state.workshop.rowCount;

export const workshop_ById = (state: any) => state.workshop.workshopById;

export const hasMore = (state: any) => state.workshop.hasMore;

export const workshop_user_list = (state: any) => state.workshop.userList;

export const user_record_count = (state: any) =>
  state.workshop.userListRecordCount;

export const sp_user_workshop = (state: any) => state.workshop.spUserWorkshop;

export const workshop_status_count = (state: any) => state.workshop.workshopStatusCount;

export const sp_workshop_record_count = (state: any) =>
  state.workshop.spWorkshopRecordCount;
