// Created By Parth Sagar

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  seminarById: any;
  seminar: any;
  rowCount: number;
  hasMore: boolean;
  seminarUserList: any;
  userListRecordCount: number;
  spSeminarRecordCount: number;
  spUserSeminar: any[];
  seminarStausList: any;
  semCount: any
}
const initialUsersState: InitialUsersState = {
  seminarById: {},
  seminar: [],
  semCount: [],
  rowCount: 0,
  hasMore: true,
  seminarUserList: {},
  userListRecordCount: 0,
  spSeminarRecordCount: 0,
  spUserSeminar: [],
  seminarStausList: [],
};

const SeminarSlice = createSlice({
  name: "seminar",
  initialState: initialUsersState,
  reducers: {
    fetchSeminar(state: any, action: any) {
      const { isSearch } = action.payload;
      if (isSearch) {
        state.seminar = action.payload.data;
      } else {
        state.seminar = action.payload.data;
      }
    },
    fetchSeminarCal(state: any, action: any) {
      state.seminar = action.payload;
    },
    fetchSeminarStatus(state: any, action: any) {
      state.seminarStausList = action.payload;
    },
    SeminarCounting(state: any, action: any) {
      state.semCount = action.payload;
    },
    setSeminar_rowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    addSeminar(state: any, action: any) {
      state?.seminar?.unshift(action.payload);
    },
    deleteSeminar(state: any, action: any) {
      state.seminar = state.seminar.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    cancelSeminar(state: any, action: any) {
      state.seminar = state.seminar.map((item: any) =>
        item._id === action?.payload.id
          ? { ...item, status: action?.payload.status }
          : item
      );
    },
    setSeminarById(state: any, action: any) {
      state.seminarById = action.payload;
    },
    editSeminar(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.seminar.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.seminar[objIndex] = {
          ...state.seminar[objIndex],
          ...updatedData,
        };
      }
    },
    setHasMore(state: any, action: any) {
      state.hasMore = action.payload;
    },
    clearSeminar(state: any) {
      state.seminar = [];
    },
    fetchSeminarUserList(state: any, action: any) {
      state.seminarUserList = action.payload;
    },
    setListCount(state: any, action: any) {
      state.userListRecordCount = action.payload;
    },
    fetchSPUserSeminar(state: any, action: any) {
      const { isSearch } = action.payload;
      if (isSearch) {
        state.spUserSeminar = action.payload.data;
      } else {
        state.spUserSeminar = action.payload.data;
      }
    },
    setSPRecordCount(state: any, action: any) {
      state.spSeminarRecordCount = action.payload;
    },
    updateSPSeminar(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.spUserSeminar.findIndex(
        (obj: any) => obj._id === updatedData?._id
      );

      if (objIndex !== -1) {
        state.spUserSeminar[objIndex] = {
          ...state.spUserSeminar[objIndex],
          ...updatedData,
        };
      }
    },
    seminarListsbyday(state: any, action: any) {
      state.seminar = action.payload;
    },
    seminarDetails(state: any, action: any) {
      state.seminar = action.payload;
    },
  },
});

export const {
  fetchSeminar,
  setRowCount,
  addSeminar,
  SeminarCounting,
  setSeminarById,
  fetchSeminarCal,
  editSeminar,
  fetchSeminarStatus,
  deleteSeminar,
  setHasMore,
  clearSeminar,
  cancelSeminar,
  setListCount,
  fetchSeminarUserList,
  fetchSPUserSeminar,
  setSPRecordCount,
  updateSPSeminar,
  seminarListsbyday,
  seminarDetails,
}: any = SeminarSlice.actions;

export default SeminarSlice.reducer;

export const seminarList = (state: any) => state?.seminar?.seminar;

export const seminarCountsList = (state: any) => state?.seminar?.semCount;

export const seminar_rowCount = (state: any) =>
  state?.seminar?.seminar_rowCount;

export const seminar_By_Id = (state: any) => state?.seminar?.seminarById;

export const hasMore = (state: any) => state.seminar.hasMore;

export const seminar_user_list = (state: any) => state.seminar.seminarUserList;

export const user_list_count = (state: any) =>
  state.seminar.userListRecordCount;

export const sp_user_seminar = (state: any) => state.seminar.spUserSeminar;

export const seminar_status_list = (state: any) => state.seminar.seminarStausList;

export const sp_seminar_record_count = (state: any) =>
  state.seminar.spSeminarRecordCount;
