// Created By RS

import { createSlice } from "@reduxjs/toolkit";

interface InitialTempState {
  template: any;
  badge: any;
  rowCount: number;
  isLoading: boolean;
}
const initialTempState: InitialTempState = {
  template: [],
  badge: [],
  rowCount: 0,
  isLoading: false,
};

const TempSlice = createSlice({
  name: "template",
  initialState: initialTempState,
  reducers: {
    fetchTemplate(state: any, action: any) {
      state.template = action.payload;
    },
    fetchBadge(state: any, action: any) {
      state.badge = action.payload;
    },
    signTemplate(state: any, action: any) {
      state.template = action.payload;
    },
    signBadge(state: any, action: any) {
      state.template = action.payload;
    },
    setTemplet_rowCount(state: any, action: any) {
      state.templet_rowCount = action.payload;
    },
    changeLoadingState(state: any, action: any) {
      state.isLoading = action.payload.loading;
    },
  },
});

export const {
  fetchTemplate,
  fetchBadge,
  setTemplet_rowCount,
  signTemplate,
  signBadge,
  changeLoadingState,
} = TempSlice.actions;

export default TempSlice.reducer;

export const tempData = (state: any) => state.template.template;

export const badgeData = (state: any) => state.template.badge;

export const templet_rowCount = (state: any) => state.template.templet_rowCount;

export const isLoading = (state: any) => state.template.isLoading;
