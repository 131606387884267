import { createSlice } from "@reduxjs/toolkit";

interface InitialFeedbackState {
    feedback: any;
    feedbackList: any;
    chats: any;
    rowCount: number;
}

const InitialFeedbackState: InitialFeedbackState = {
    feedback: [],
    feedbackList: [],
    chats: [],
    rowCount: 0,
};

const feedbackSlice = createSlice({
    name: "feedback",
    initialState: InitialFeedbackState,
    reducers: {
        fetchFeedbackCMS(state: any, action: any) {
            state.feedback = action.payload;
        },
        FeedbackDataList(state: any, action: any) {
            state.feedbackList = action.payload;
        },
        setFeedbackCount(state: any, action: any) {
            state.rowCount = action.payload
        },
        deleteInquiry(state: any, action: any) {
            state.feedback = state.feedback.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        addComment(state: any, action: any) {
            state?.chats?.comments?.push(action.payload)
        },
        setFindInquiryById(state: any, action: any) {
            state.chats = action.payload;
        },
        deleteComment(state, action) {
            state.chats.comments = state.chats.comments.filter((chat: any) => chat._id !== action.payload);
        }
    },
});

export const {
    fetchFeedbackCMS,
    FeedbackDataList,
    setFeedbackCount,
    deleteInquiry,
    addComment,
    setFindInquiryById,
    deleteComment
} = feedbackSlice.actions;

export default feedbackSlice.reducer;

export const feedbacklList = (state: any) => state.feedback.feedback;

export const feedback_Count = (state: any) => state.feedback.rowCount;

export const feedback_DataList = (state: any) => state.feedback.feedbackList;

export const currentChatsData = (state: any) => state.feedback.chats