import { createSlice } from "@reduxjs/toolkit";

interface InitialSchoolState {
  school: any;
  schoolCMS: any;
  school_rowCount: number;
  schoolById: any;
}

const InitialSchoolState: InitialSchoolState = {
  school: [],
  schoolCMS: [],
  school_rowCount: 0,
  schoolById: {},
};

const schoolSlice = createSlice({
  name: "school",
  initialState: InitialSchoolState,
  reducers: {
    fetchSchool(state: any, action: any) {
      state.school = action.payload;
    },
    fetchSchoolCMS(state: any, action: any) {
      state.schoolCMS = action.payload;
    },
    addSchool(state: any, action: any) {
      state?.school?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    setSchoolRowCount(state: any, action: any) {
      state.school_rowCount = action.payload;
    },
    setSchoolById(state: any, action: any) {
      state.schoolById = action.payload;
    },
    editSchool(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.school.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.school[objIndex] = {
          ...state.school[objIndex],
          ...updatedData,
        };
      }
    },
    deleteSchool(state: any, action: any) {
      state.school = state.school.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    clearSchool: (state: any) => {
      state.schoolById = {};
    },
  },
});

export const {
  fetchSchool,
  addSchool,
  setSchoolRowCount,
  setSchoolById, 
  editSchool,
  clearSchool,
  fetchSchoolCMS,
  deleteSchool
} = schoolSlice.actions;

export default schoolSlice.reducer;

export const school_list = (state: any) => state.school.school;

export const schoolList = (state: any) => state.school.schoolCMS;

export const schoolById = (state: any) => state.school.schoolById;