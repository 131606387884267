// Created By RS

import { createSlice } from "@reduxjs/toolkit";

interface InitialCertiState {
  userCertificate: any;
  userCertificate_rowCount: number;
  isLoading: boolean;
}
const InitialCertiState: InitialCertiState = {
  userCertificate: [],
  userCertificate_rowCount: 0,
  isLoading: false,
};

const CertiSlice = createSlice({
  name: "userCertificate",
  initialState: InitialCertiState,
  reducers: {
    fetchCertificate(state: any, action: any) {
      state.template = action.payload;
    },
  },
});

export const { fetchCertificate } = CertiSlice.actions;

export default CertiSlice.reducer;

export const tempData = (state: any) => state.userCertificate.template;

export const userCertificate_rowCount = (state: any) =>
  state.userCertificate.userCertificate_rowCount;
