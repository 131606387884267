import { createSlice } from "@reduxjs/toolkit";

interface InitialNewsLetterState {
    newsLetterlist: any;
    rowCount: number,
}

const InitialNewsLetterState: InitialNewsLetterState = {
    newsLetterlist: [],
    rowCount: 0,
};

const newsLetterSlice = createSlice({
    name: "newLetter",
    initialState: InitialNewsLetterState,
    reducers: {
        newLetterList(state: any, action: any) {
            state.newsLetterlist = action.payload;
        },
        setNewsletterCount(state: any, action: any) {
            state.rowCount = action.payload
        },
    },
});

export const {
    newLetterList,
    setNewsletterCount
} = newsLetterSlice.actions;

export default newsLetterSlice.reducer;

export const newsLetter_list = (state: any) => state.newLetter.newsLetterlist;

export const newsLetter_count = (state: any) => state.newLetter.rowCount;