// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialFormBuilderState {
  getCoupon: any;
  formBuilder: any;
  customFormByCatId: any;
  rowCount: number;
  categoryList: any;
  formBuilderById: any;
  catCount: number;
}
const initialUsersState: InitialFormBuilderState = {
  getCoupon: [],
  formBuilder: [],
  customFormByCatId: [],
  rowCount: 0,
  categoryList: [],
  formBuilderById: [],
  catCount: 0,
};

const formBuilderSlice = createSlice({
  name: "formBuilder",
  initialState: initialUsersState,
  reducers: {
    getCouponData(state: any, action: any) {
      state.getCoupon = action.payload;
    },
    getFormData(state: any, action: any) {
      state.formBuilder = action.payload;
    },
    setCustomFormById(state: any, action: any) {
      state.formBuilderById = action.payload;
    },
    deleteFormData(state: any, action: any) {
      state.formBuilder = state.formBuilder.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    getCusFormData(state: any, action: any) {
      state.customFormByCatId = action.payload;
    },
    getCusFormNull(state: any) {
      state.customFormByCatId = [];
    },
    fetchCategoryList(state: any, action: any) {
      state.categoryList = [...state.categoryList, ...action.payload];
    },
    emptyCatList(state: any) {
      state.categoryList = []
      state.catCount = 0
    },
    setCatCount(state: any, action: any) {
      state.catCount = action.payload;
    },
  },
});

export const {
  getFormData,
  getCouponData,
  deleteFormData,
  setRowCount,
  getCusFormData,
  getCusFormNull,
  fetchCategoryList,
  setCatCount,
  emptyCatList,
  setCustomFormById,
} = formBuilderSlice.actions;

export default formBuilderSlice.reducer;
export const formData = (state: any) => state.formBuilder.formBuilder;
export const couponData = (state: any) => state.formBuilder.getCoupon;
export const customFormByCatId = (state: any) => state.formBuilder.customFormByCatId;
export const rowCount = (state: any) => state.formBuilder.rowCount;
export const categorylist = (state: any) => state.formBuilder.categoryList;
export const catCount = (state: any) => state.formBuilder.catCount;
export const formBuilderByIds = (state: any) => state.formBuilder.formBuilderById;
