// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  competition: any;
  rowCount: number;
  CompititionById: any;
  hasMore: boolean;
}
const eventsState: InitialUsersState = {
  competition: [],
  rowCount: 0,
  CompititionById: {},
  hasMore: true
};

const CompetitionSlice = createSlice({
  name: "competition",
  initialState: eventsState,
  reducers: {
    fetchCompetition(state: any, action: any) {
      const { isSearch } = action.payload
      if (isSearch) {
        state.competition = action.payload.data
      } else {
        state.competition = action.payload.data
      }
    },
    fetchCompetitionCal(state: any, action: any) {
      state.competition = action.payload
    },
    setRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    addCompetition(state: any, action: any) {
      state?.competition?.push(action.payload);
    },
    editCompetition(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.competition.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.competition[objIndex] = {
          ...state.competition[objIndex],
          ...updatedData,
        };
      }
    },
    deleteCompetition(state: any, action: any) {
      state.competition = state.competition.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    cancelCompetition(state: any, action: any) {
      state.competition = state.competition.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setCompetitionById(state: any, action: any) {
      state.CompititionById = action.payload;
    },
    setHasMore(state: any, action: any) {
      state.hasMore = action.payload
    },
    clearCompetition(state: any) {
      state.competition = []
    }
  },
});

export const {
  setHasMore,
  clearCompetition,
  addCompetition,
  editCompetition,
  fetchCompetitionCal,
  deleteCompetition,
  setCompetitionById,
  fetchCompetition,
  cancelCompetition,
  setRowCount,
}: any = CompetitionSlice.actions;

export default CompetitionSlice.reducer;

export const competitionList = (state: any) => state.competition.competition;

export const rowCount = (state: any) => state.competition.rowCount;

export const Compitition_By_Id = (state: any) => state.competition.CompititionById;

export const hasMore = (state: any) => state.competition.hasMore

