// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
    zone: any;
    rowCount: number;
}
const initialUsersState: InitialUsersState = {
    zone: [],
    rowCount: 0
};

const InteractiveSlice = createSlice({
    name: "zone",
    initialState: initialUsersState,
    reducers: {
        fetchEvents(state: any, action: any) {
            state.zone = action.payload;
        },
        setRowCount(state: any, action: any) {
            state.rowCount = action.payload
        },
    },
});

export const { fetchEvents, setRowCount } = InteractiveSlice.actions;

export default InteractiveSlice.reducer;

export const interactiveList = (state: any) => state.zone.zone;
export const rowCount = (state: any) => state.zone.rowCount;