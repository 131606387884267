// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialCategoryListState {
  categoryList: any;
  memberPlan_List: any;
  setPlan_rowCount: number;
  subCategorylist: any;
  plan: any;
  planById: any;
}

const InitialCategoryListState: InitialCategoryListState = {
  categoryList: [],
  memberPlan_List: [],
  setPlan_rowCount: 0,
  subCategorylist: [],
  plan: [],
  planById: {},
};

const categoryListSlice = createSlice({
  name: "categoryList",
  initialState: InitialCategoryListState,
  reducers: {
    fetchCategoryList(state: any, action: any) {
      state.categoryList = action.payload
    },
    setPlan_rowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    fetchSubCategoryList(state: any, action: any) {
      state.subCategorylist = action.payload;
    },
    planList(state: any, action: any) {
      state.plan = action.payload;
    },
    addPlan(state: any, action: any) {
      state?.categoryList?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    editPlan(state: any, action: any) {
      const objIndex = state.categoryList.findIndex(
        (obj: any) => obj._id === action.payload?.id
      );

      if (objIndex !== -1) {
        state.categoryList[objIndex] = {
          ...state.categoryList[objIndex],
          ...action.payload,
        };
      }
    },
    deletePlan(state: any, action: any) {
      state.categoryList = state.categoryList.filter(
        (obj: any) => obj._id !== action?.payload?.id
      );
    },
    setPlanById(state: any, action: any) {
      state.planById = action.payload;
    },
    getMemberPlan(state: any, action: any) {
      state.memberPlan_List = action.payload;
    },
  },
});

export const {
  fetchCategoryList,
  fetchSubCategoryList,
  setPlan_rowCount,
  addPlan,
  editPlan,
  planList,
  setPlanById,
  deletePlan,
  getMemberPlan
} = categoryListSlice.actions;

export default categoryListSlice.reducer;

export const categorylist = (state: any) => state.categoryList.categoryList;

export const memberplanList = (state: any) => state.categoryList.memberPlan_List;

export const plan_rowCount = (state: any) => state.categoryList.plan_rowCount;

export const subCategorylist = (state: any) =>
  state.categoryList.subCategorylist;

export const planData = (state: any) => state.categoryList.plan;

export const planById = (state: any) => state.categoryList.planById;
