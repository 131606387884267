import apiCall from "../../../Helpers/Services";
import { addwebSettingsData, getWebSettings, addBenefits, editBenefits, deleteBenefits, addEducation, editEducation, deleteEducation, addRecords, editRecords, deleteRecords, getUpcomingEvents, getFooter } from "./HomePageSlice";

export const addWebData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/webSettings",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(addwebSettingsData({ ...passData, _id: data?.id }));

  return data;
};

export const getWebSettingsLists = async (id: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "GET",
    url: "settings/getWebSettings",
    data: {},
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(getWebSettings(data?.data));
  }
  return data;
};

export const getWebSettingsHomeLists = async (id: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "GET",
    url: "settings/fetchWebSettings",
    data: {},
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(getWebSettings(data?.data));
  }
  return data;
};

export const getFooterData = async (id: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "GET",
    url: "contactUs/getContactDetailsData",
    data: {},
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(getFooter(data?.data));
  }
  return data;
};



export const uploadCmsFiles = async (passData: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/uploadFile",
    data: passData,
    isMultipart: true,
    isHideTostMsg: true,
  });

  return data;
};

// export const uploadMemories = async (passData: any) => {
//   const data: any = await apiCall({
//     method: "POST",
//     url: "settings/uploadFiles",
//     data: passData,
//     isMultipart: true,
//     isHideTostMsg: true,
//   });

//   return data;
// };

export const addBenefitsData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/addBenefits",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(addBenefits(data?.data))

  return data;
};

export const addEducationData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/addEducation",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(addEducation(data?.data))

  return data;
};

export const addRecordsData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/addCounter",
    data: passData,
    // isMultipart: true,
  });

  data?.status && dispatch(addRecords(data?.data))

  return data;
};

export const editBenefitsData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/editBenefits",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(editBenefits(passData))

  return data;
};

export const editRecordsData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/editCounter",
    data: passData,
    // isMultipart: true,
  });

  data?.status && dispatch(editRecords(passData))

  return data;
};

export const editEducationData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/editEducation",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(editEducation(passData))

  return data;
};

export const deleteBenefitsData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/deleteBenefits",
    data: passData,
  });

  data?.status && dispatch(deleteBenefits(passData?.id))

  return data;
};

export const deleteRecordsData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/deleteCounter",
    data: passData,
  });

  data?.status && dispatch(deleteRecords(passData?.id))

  return data;
};

export const deleteEducationData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/deleteEducation",
    data: passData,
  });

  data?.status && dispatch(deleteEducation(passData?.id))

  return data;
};

export const fetchupcomingEvents = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "userevent/fetchEventList",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(getUpcomingEvents(data?.data));
  }
  return data;
};

