// Created By MK

import { createSlice } from "@reduxjs/toolkit";
import AppUtils from "../../../Helpers/AppUtils";

interface InitialAuthState {
  authData: any;
  catUserDashboard: any,
  isLoading: boolean;
  data: any;
  unreadNotiCount: number
  payData: any;
}

const initialAuthState: InitialAuthState = {
  authData: AppUtils.getUserDtls() ? AppUtils.getUserDtls() : {},
  catUserDashboard: {},
  isLoading: false,
  data: "",
  unreadNotiCount: 0,
  payData: {},
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    onSuccessfulAuth(state: any, action: any) {
      state.authData = action.payload;
    },
    catUserData(state: any, action: any) {
      state.catUserDashboard = action.payload;
    },
    changeLoadingState(state: any, action: any) {
      state.isLoading = action.payload.loading;
    },
    onSuccessfulLogout(state: any) {
      state.authData = {};
    },
    getUserID(state: any, action: any) {
      state.data = action.payload;
    },
    otpData(state: any, action: any) {
      state.data = action.payload;
    },
    setUnreadNoti(state: any, action: any) {
      state.unreadNotiCount = action.payload;
    },
    decreaseNoti(state: any) {
      state.unreadNotiCount = state.unreadNotiCount - 1;
    },
    onPaymentAuth(state: any, action: any) {
      state.payData = action.payload;
    },
  },
});

export const { onSuccessfulAuth, catUserData, onSuccessfulLogout, changeLoadingState, getUserID, otpData, setUnreadNoti, decreaseNoti, onPaymentAuth } =
  AuthSlice.actions;

export default AuthSlice.reducer;

export const authData = (state: any) => state.auth.authData;

export const catUserDdata = (state: any) => state.auth.catUserDashboard;

export const data = (state: any) => state.auth.data;

export const unreadNotiCount = (state: any) => state.auth.unreadNotiCount;

export const pay_Data = (state: any) => state.auth.payData;
