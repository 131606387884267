// Created By MK

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  event: any;
  level: any;
  eventlist_rowCount: number;
  levelrowCount: number;
  isLoading: boolean;
  EventById: any;
  EventUser: any;
  eventDetails: any;
}
const initialUsersState: InitialUsersState = {
  event: [],
  eventDetails: [],
  level: [],
  eventlist_rowCount: 0,
  levelrowCount: 0,
  isLoading: false,
  EventById: {},
  EventUser: {},
};

const EventSlice = createSlice({
  name: "event",
  initialState: initialUsersState,
  reducers: {
    fetchEvents(state: any, action: any) {
      state.event = action.payload;
    },
    eveDetails(state: any, action: any) {
      state.event = action.payload;
    },
    fetchLevel(state: any, action: any) {
      state.level = [...state.level, ...action.payload];
    },
    setEventlist_rowCount(state: any, action: any) {
      state.eventlist_rowCount = action.payload;
    },
    setlevelRowCount(state: any, action: any) {
      state.levelrowCount = action.payload;
    },
    addEvent(state: any, action: any) {
      state?.event?.push(action.payload);
    },
    editEvent(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.event.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.event[objIndex] = {
          ...state.event[objIndex],
          ...updatedData,
        };
      }
    },
    deleteEvent(state: any, action: any) {
      state.event = state.event.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setEventById(state: any, action: any) {
      state.EventById = action.payload;
    },
    clearEvent: (state: any) => {
      state.EventById = {};
    },
    fetchEventsUser(state: any, action: any) {
      state.EventUser = action.payload;
    },
  },
});

export const {
  fetchEvents,
  fetchLevel,
  setEventlist_rowCount,
  setlevelRowCount,
  addEvent,
  editEvent,
  deleteEvent,
  setEventById,
  clearEvent,
  fetchEventsUser,
  eveDetails,
} = EventSlice.actions;

export default EventSlice.reducer;

export const event_list = (state: any) => state.event.event;

// export const event_details = (state: any) => state.event.eveDetails;

export const level_List = (state: any) => state.event.level;

export const eventlist_rowCount = (state: any) =>
  state.event.eventlist_rowCount;

export const levelrowCount = (state: any) => state.event.levelrowCount;

export const EventById = (state: any) => state.event.EventById;

export const EventUser = (state: any) => state.event.EventUser;
