// Created By MK

import { createSlice } from "@reduxjs/toolkit";

interface InitialBlogsState {
  blogs: any;
  blogsStudent: any;
  hasMore: boolean;
  HomePageblogs: any;
  blogCat: any;
  blogCate_rowCount: number;
  blogCategoryById: any;
  blogListById: any;
  blogDrop: any;
  blogNewsList: any;
  blogsStatusCount: any;
  blogByIdData: any;
}
const blogsState: InitialBlogsState = {
  blogs: [],
  blogListById: [],
  blogsStudent: [],
  blogDrop: [],
  blogNewsList: [],
  hasMore: true,
  HomePageblogs: [],
  blogCat: [],
  blogsStatusCount: [],
  blogByIdData: [],
  blogCate_rowCount: 0,
  blogCategoryById: {},
};

const BlogsSlice = createSlice({
  name: "blogs",
  initialState: blogsState,
  reducers: {
    fetchBlogsList(state: any, action: any) {
      state.blogsStudent = action.payload;
    },
    setUserBlogById(state: any, action: any) {
      state.blogByIdData = action.payload;
    },
    fetchBlogsCounts(state: any, action: any) {
      state.blogsStatusCount = action.payload;
    },
    getBlogNewsList(state: any, action: any) {
      state.blogNewsList = action.payload;
    },
    blogDropdown(state: any, action: any) {
      state.blogDrop = action.payload;
    },
    fetchBlogs(state: any, action: any) {
      state.blogs = action.payload;
    },
    fetchBlogListById(state: any, action: any) {
      state.blogListById = action.payload;
    },
    setHasMore(state: any) {
      const data = false;
      state.hasMore = data;
    },
    deleteBlog(state: any, action: any) {
      state.blogs = state.blogs.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    getblogList(state: any, action: any) {
      state.HomePageblogs = action.payload;
    },
    addBlogCategory(state: any, action: any) {
      state?.blogCat?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    editBlogCategory(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.blogCat.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.blogCat[objIndex] = {
          ...state.blogCat[objIndex],
          ...updatedData,
        };
      }
    },
    setBlogCategoryById(state: any, action: any) {
      state.blogCategoryById = action.payload;
    },
    fetchBlogCateList(state: any, action: any) {
      state.blogCat = action.payload;
    },
    setBlogCate_rowCount(state: any, action: any) {
      state.blogCate_rowCount = action.payload;
    },
    deleteBlogCategory(state: any, action: any) {
      state.blogCat = state.blogCat.filter(
        (item: any) => item._id !== action?.payload
      );
    },
  },
});

export const {
  fetchBlogsList,
  setHasMore,
  fetchBlogs,
  deleteBlog,
  getblogList,
  blogDropdown,
  addBlogCategory,
  editBlogCategory,
  setBlogCategoryById,
  deleteBlogCategory,
  fetchBlogCateList,
  setBlogCate_rowCount,
  fetchBlogListById,
  getBlogNewsList,
  fetchBlogsCounts,
  setUserBlogById,
} = BlogsSlice.actions;

export default BlogsSlice.reducer;

export const blogStudent = (state: any) => state.blogs.blogsStudent;
export const blogsStatusCountStudent = (state: any) =>
  state.blogs.blogsStatusCount;
export const blogs = (state: any) => state.blogs.blogs;
export const blogList_byId = (state: any) => state.blogs.blogListById;
export const blogNewsList = (state: any) => state.blogs.blogNewsList;
export const blogDrop = (state: any) => state.blogs.blogDrop;
export const HomePage_blogs = (state: any) => state.blogs.HomePageblogs;

export const blogCate_list = (state: any) => state.blogs.blogCat;
export const blogCate_rowCount = (state: any) => state.blogs.blogCate_rowCount;
export const blogCategoryById = (state: any) => state.blogs.blogCategoryById;
export const blogByIdDatas = (state: any) => state.blogs.blogByIdData;
