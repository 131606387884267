import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import { Provider } from "react-redux";
import store from "./ReduxToolkit/Store";
import AppUtils from "./Helpers/AppUtils";
import { Notifications } from 'react-push-notification';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

AppUtils.fetchPublicIp().then(() => {
  root.render(
    <Provider store={store}>
      <Notifications />
      <App />
    </Provider>
  );
});