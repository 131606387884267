// Created By MK

const mode: any = {
  PRODUCTION: "https://isso.tjcg.in",
  DEVELOPMENT: "https://isso.tjcg.in",
};

let appMode: any = process.env.REACT_APP_MODE;

if (!appMode || !mode.hasOwnProperty(appMode)) {
  console.error("Invalid or missing app mode. Defaulting to development.");
  appMode = "DEVELOPMENT";
}

const UrlHelper = {
  serverUrl: mode[appMode],
  serverImageUrl: mode[appMode] + "/api/",
};

export default UrlHelper;
