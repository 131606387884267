// Created By Yash l.

import { createSlice } from "@reduxjs/toolkit";

interface InitialnewsCateListState {
  newsCateList: any;
  newsCate_rowCount: number;
  newsCategoryById: any;
  randomNews: any;
  selectNewsList: any;
  mainPageData: any;
}

const InitialnewsCateListState: InitialnewsCateListState = {
  newsCateList: [],
  newsCate_rowCount: 0,
  newsCategoryById: {},
  randomNews: [],
  selectNewsList: [],
  mainPageData: [],
};

const newsCateListSlice = createSlice({
  name: "newsCateList",
  initialState: InitialnewsCateListState,
  reducers: {
    fetchNewsCateList(state: any, action: any) {
      state.newsCateList = action.payload;
    },
    setNewsCate_rowCount(state: any, action: any) {
      state.newsCate_rowCount = action.payload;
    },
    addNewsCategory(state: any, action: any) {
      state?.newsCateList?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    deleteNewsCategory(state: any, action: any) {
      state.newsCateList = state.newsCateList.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setNewsCategoryById(state: any, action: any) {
      state.newsCategoryById = action.payload;
    },
    editNewsCategory(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.newsCateList.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.newsCateList[objIndex] = {
          ...state.newsCateList[objIndex],
          ...updatedData,
        };
      }
    },
    randomNewsData(state: any, action: any) {
      state.randomNews = action.payload;
    },
    selectNews(state: any, action: any) {
      state.selectNewsList = action.payload;
    },
    getNewsmainpage(state: any, action: any) {
      state.mainPageData = action.payload;
    }
  },
});

export const {
  fetchNewsCateList,
  setNewsCate_rowCount,
  addNewsCategory,
  deleteNewsCategory,
  setNewsCategoryById,
  editNewsCategory,
  randomNewsData,
  selectNews,
  getNewsmainpage,
} = newsCateListSlice.actions;

export default newsCateListSlice.reducer;

export const newsCate_list = (state: any) => state.newsCateList.newsCateList;

export const randomNews_list = (state: any) => state.newsCateList.randomNews;

export const selectNews_list = (state: any) => state.newsCateList.selectNewsList;

export const newsCate_rowCount = (state: any) => state.newsCateList.newsCate_rowCount;

export const newsCategoryById = (state: any) => state.newsCateList.newsCategoryById;

export const mainPage_Data = (state: any) => state.newsCateList.mainPageData;

