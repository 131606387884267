// Created By Yash l.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
    dashboards: any;
    certificate: any;
    user: any;
    counts: any;
    certiVerification: any;
    renewal: any;
    categoryList: any;
    catCount: number;
    eventcountList: number;
    seminarcountList: number;
    workshopcountList: number;
}
const initialUsersState: InitialUsersState = {
    dashboards: [],
    certificate: [],
    counts: [],
    user: [],
    certiVerification: [],
    renewal: [],
    categoryList: [],
    catCount: 0,
    eventcountList: 0,
    seminarcountList: 0,
    workshopcountList: 0,
};

const DashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialUsersState,
    reducers: {
        fetchDashboard(state: any, action: any) {
            state.dashboards = action.payload;
        },
        countsList(state: any, action: any) {
            state.counts = action.payload;
        },
        certificateIssuance(state: any, action: any) {
            state.certificate = action.payload;
        },
        userActivity(state: any, action: any) {
            state.user = action.payload;
        },
        verificationList(state: any, action: any) {
            state.certiVerification = action.payload;
        },
        renewalCertificatesList(state: any, action: any) {
            state.renewal = action.payload;
        },
        fetchCategoryList(state: any, action: any) {
            state.categoryList = [...state.categoryList, ...action.payload];
        },
        emptyCatList(state: any) {
            state.categoryList = []
            state.catCount = 0
        },
        setCatCount(state: any, action: any) {
            state.catCount = action.payload;
        },
        setEventCount(state: any, action: any) {
            state.eventcountList = action.payload;
        },
        setSeminarCount(state: any, action: any) {
            state.seminarcountList = action.payload;
        },
        setWorkshopCount(state: any, action: any) {
            state.workshopcountList = action.payload;
        },
    },
});

export const {
    fetchDashboard,
    countsList,
    certificateIssuance,
    userActivity,
    verificationList,
    renewalCertificatesList,
    fetchCategoryList,
    setCatCount,
    emptyCatList,
    setEventCount,
    setSeminarCount,
    setWorkshopCount,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;

export const dashboardCharts = (state: any) => state.dashboard.dashboards;
export const allCountList = (state: any) => state.dashboard.counts;
export const allcertificateList = (state: any) => state.dashboard.certificate;
export const userList = (state: any) => state.dashboard.user;
export const certiVerificationList = (state: any) => state.dashboard.certiVerification;
export const renewalList = (state: any) => state.dashboard.renewal;
export const categorylist = (state: any) => state.dashboard.categoryList;
export const catCount = (state: any) => state.dashboard.catCount;
export const eventCount = (state: any) => state.dashboard.eventcountList;
export const seminarCount = (state: any) => state.dashboard.eventcountList;
export const workshopCount = (state: any) => state.dashboard.eventcountList;