// Created By Raj.

// Slice
import { createSlice } from "@reduxjs/toolkit";

interface InitialTandCState {
  termsAndConditions: any;
  getTermsAndCon: any;
}

const InitialtermsAndConState: InitialTandCState = {
  termsAndConditions: [],
  getTermsAndCon: [],
};

const termsAndConditionSlice = createSlice({
  name: "termsAndConditions",
  initialState: InitialtermsAndConState,
  reducers: {
    tAndc(state: any, action: any) {
      state?.termsAndConditions?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    getTermsAndConditions(state: any, action: any) {
      state.getTermsAndCon = action.payload;
    },
  },
});

export const { tAndc, getTermsAndConditions } = termsAndConditionSlice.actions;

export default termsAndConditionSlice.reducer;

export const tandc = (state: any) => state.termsAndConditions.termsAndConditions;

export const termsAconditionData = (state: any) => state.termsAndConditions.getTermsAndCon;
