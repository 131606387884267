// Created By MK

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  users: any;
  usersByCat: any;
  roles: any;
  rowCount: number;
  rowCountcatid: number;
  isLoading: boolean;
  permission: any;
  user_id: string;
  notification: any;
  notiCount: number;
  countrystatecity:string;
}
const initialUsersState: InitialUsersState = {
  users: [],
  usersByCat: [],
  roles: [],
  rowCount: 0,
  rowCountcatid: 0,
  isLoading: false,
  permission: {},
  user_id: "",
  notification: [],
  notiCount: 0,
  countrystatecity:"",
};

const UsersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    fetchUsers(state: any, action: any) {
      state.users = action.payload;
    },
    fetchUsersCategory(state: any, action: any) {
      state.usersByCat = action.payload;
    },
    fetchRoles(state: any, action: any) {
      state.roles = [...state.roles, ...action.payload];
    },
    addUser(state: any, action: any) {
      state.users.push(action.payload);
    },
    setRowCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    setUserCatRowCount(state: any, action: any) {
      state.rowCountcatid = action.payload;
    },
    editUser(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.users.hasOwnProperty(userId)) {
        state.users[userId] = { ...state.users[userId], ...action.payload };
      }
    },
    deleteUsers(state: any, action: any) {
      state.users = state.users.filter(
        (obj: any) => obj._id !== action?.payload?.id
      );
    },
    changeLoadingState(state: any, action: any) {
      state.isLoading = action.payload.loading;
    },
    updatePermission(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.permission.hasOwnProperty(userId)) {
        state.permission[userId] = { ...state.permission[userId], ...action.payload };
      }
    },
    getPermissionById(state: any, action: any) {
      state.permission = action.payload;
    },
    setuserById(state: any, action: any) {
      state.users = action.payload;
    },
    setUserId(state: any, action: any) {
      state.user_id = action.payload;
    },
    fetchNotification(state: any, action: any) {
      state.notification = action.payload;
    },
    setNotiCount(state: any, action: any) {
      state.notiCount = action.payload
    },
    setMarkAsRead(state: any, action: any) {
      state.notification = state.notification.map((notif: any) =>
        notif._id === action.payload ? { ...notif, is_read: true } : notif
      );
    },
    setcountryData(state: any, action: any) {
      state.countrystatecity = action.payload;
    },
  },
});

export const {
  fetchUsers,
  fetchUsersCategory,
  fetchRoles,
  addUser,
  editUser,
  deleteUsers,
  setRowCount,
  changeLoadingState,
  updatePermission,
  getPermissionById,
  setuserById,
  setUserId,
  setUserCatRowCount,
  fetchNotification,
  setNotiCount,
  setMarkAsRead,
  setcountryData,
} = UsersSlice.actions;

export default UsersSlice.reducer;

export const usersData = (state: any) => state.users.users
export const usersDataBycat = (state: any) => state.users.usersByCat;
export const rolesData = (state: any) => state.users.roles;
export const rowCount = (state: any) => state.users.rowCount;
export const rowCountByCat = (state: any) => state.users.rowCountcatid;
export const getPermission_ById = (state: any) => state.users.permission;
export const notification = (state: any) => state.users.notification;
export const noti_Count = (state: any) => state.users.notiCount;
export const cou_stat_city = (state: any) => state.users.countrystatecity


