// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialCalenderState {
  calender: any;
  calenderListData: any;
  hasMore: boolean;
}
const calenderState: InitialCalenderState = {
  calender: [],
  calenderListData: [],
  hasMore: true,
};

const CompetitionSlice = createSlice({
  name: "calender",
  initialState: calenderState,
  reducers: {
    fetchCalender(state: any, action: any) {
      state.calender = action.payload;
    },
    setHasMore(state: any) {
      const data = false;
      state.hasMore = data;
    },
    fetchCalLists(state: any, action: any) {
      state.calenderListData = action.payload;
    },
    addCalData(state: any, action: any) {
      state?.calender?.push(action.payload)
    },
    getCalEventsList(state: any, action: any) {
      state.calenderListData = action.payload;
    },
    addCalEventsDatas(state: any, action: any) {
      state.calenderListData = action.payload;
    },
    editCalEvents(state: any, action: any) {
      state.calenderListData.events = state?.calenderListData?.events?.map((item: any) => {
        if (item._id === action.payload?.id) {
          return { ...item, ...action.payload }
        }

        return item
      })
    },
    deleteCalEvents(state: any, action: any) {
      const updatedProcesssteps = state?.calenderListData?.events.filter((item: any) => item._id !== action.payload);
      state.calenderListData.events = updatedProcesssteps;
    },
  },
});

export const { setHasMore, fetchCalender, fetchCalLists, addCalData, getCalEventsList, addCalEventsDatas, editCalEvents, deleteCalEvents } = CompetitionSlice.actions;

export default CompetitionSlice.reducer;

export const competitionList = (state: any) => state.calender.competition;

export const calListData = (state: any) => state.calender.calenderListData;

export const rowCount = (state: any) => state.calender.rowCount;
