import ISSOLogo from "../../Assets/Image/ISSO logo.svg";
import ISSOLogoPlaceholder from "../../Assets/Image/ISSOLogoPlaceholder.svg";
import AboutRightRound from "../../Assets/Image/AboutRightRound.svg";
import RightRoundgreen from "../../Assets/Image/RightRoundgreen.svg";
import event from "../../Assets/Image/Event.svg";
import workshop from "../../Assets/Image/WorkshopIcon.svg";
import seminar from "../../Assets/Image/SeminarIcon.svg";
import regdeadline from "../../Assets/Image/registrationdeadline.svg";
import Upcoming from "../../Assets/Image/UpcomingHomePage.svg";
import seminarDash from "../../Assets/Image/DashSeminarWhite.svg";
import workshopDash from "../../Assets/Image/DashWorkshopWhite.svg";
import Feature from "../../Assets/Image/BlogNnewsPlaceholder.svg";
import user1 from "../../Assets/Image/TestimonialPlaceHolder.svg";
import Path from "../../Assets/Image/Path.svg";
import EditCom from "../../Assets/Image/EditCom.svg";
import DeleteCom from "../../Assets/Image/DeleteCom.svg";
import facebookIcon from '../../Assets/Image/FacebookIcon.svg'
import twitterIcon from '../../Assets/Image/TwitterIcons.svg'
import instagramIcon from '../../Assets/Image/Instagram.svg'
import youtube from '../../Assets/Image/Youtube.svg';
import InquiryImg from "../../Assets/Image/Inquirybackgroundimg.svg";
import pointer from "../../Assets/Image/memberstep.svg";
import benifits from "../../Assets/Image/benifittopleft.svg";
import DateCal from "../../Assets/Image/DateCal.svg";
import ChatIcon from "../../Assets/Image/ChatIcon.svg";
import TimerClock from "../../Assets/Image/TimerClock.svg";
import TowerIcon from "../../Assets/Image/Tower.svg";
import girlImage from "../../Assets/Image/GirlImageWhite.svg";
import UserCat from "../../Assets/Image/UserCat.svg";
import Calendar from "../../Assets/Image/Calendar.svg";
import CalendarPurple from "../../Assets/Image/CalendarPurple.svg";
import Clock from "../../Assets/Image/Clock.svg";
import ClockStudent from "../../Assets/Image/ClockStudent.svg";
import ISSOCircle from "../../Assets/Image/ISSOCircle.svg";
import shining from "../../Assets/Image/ShiningFill.svg";
import Circle from "../../Assets/Image/Testimonialrightround.svg";
import SubscribeMail from "../../Assets/Image/SubscribeMail.svg"
import clockIcon from "../../Assets/Image/Clock.svg";
import locationIcon from "../../Assets/Image/Location.svg";
import seatIcon from '../../Assets/Image/SeatIcon.svg';
import calImg from '../../Assets/Image/CalImage.svg'
import watchImg from '../../Assets/Image/WatchImage.svg'
import microsoftExcel from "../../Assets/Image/microsoft-excel-icon.svg";
import rightArrowIcon from "../../Assets/Image/RightArrowIcon.svg";
import PdfIcon from "../../Assets/Image/pdf-red-icon.svg";
import microsoftWordIcon from "../../Assets/Image/microsoft-word-icon.svg";
import leftRound from "../../Assets/Image/LeftRound.svg";
import rightRound from "../../Assets/Image/RightRound.svg";
import right from "../../Assets/Image/RightArrow.svg";
import call from "../../Assets/Image/Call.svg";
import inbox from "../../Assets/Image/Inbox.svg";
import map from "../../Assets/Image/Map.svg";
import FooterImgLeft from "../../Assets/Image/FooterImgLeft.svg";
import FooterRight from "../../Assets/Image/FooterRight.svg";
import EventRegIcon from "../../Assets/Image/EventRegIcon.svg";
import EventApproved from "../../Assets/Image/EventApprovedIcon.svg";
import EventOnGoingIcon from "../../Assets/Image/EventOnGoingIcon.svg";
import EventUpcomingIcon from "../../Assets/Image/eventUpcomingIcon.svg";
import EventCancelIcon from "../../Assets/Image/EventCancelIcon.svg";
import EventCountIcon from "../../Assets/Image/EventCountIcon.svg";
import EventArchive from "../../Assets/Image/EventArchive.svg";
import Profile from "../../Assets/Image/UserCircle.svg";
import BlackClock from "../../Assets/Image/BlackClock.svg";
import BellIcon from "../../Assets/Image/Bell.svg"
import RoleI from "../../Assets/Image/Role.svg";
import CategoryI from "../../Assets/Image/Doc.svg";
import CertificateI from "../../Assets/Image/Certificate.svg";
import NewsAnInsI from "../../Assets/Image/Newsanins.svg";
import EventsI from "../../Assets/Image/Events.svg";
import SeminarI from "../../Assets/Image/Seminar.svg";
import WorkshopI from "../../Assets/Image/WorkshopI.svg";
import CalendarI from "../../Assets/Image/CalendarImg.svg";
import FeedbackI from "../../Assets/Image/FeedbackImage.svg";
import FAQI from "../../Assets/Image/FAQ.svg";
import TACI from "../../Assets/Image/TAC.svg";
import DashboardI from "../../Assets/Image/Dashboard.svg";
import Cms from "../../Assets/Image/Cms.svg";
import SportsI from "../../Assets/Image/SportTypeI.svg";
import CommunityI from "../../Assets/Image/Community.svg";
import PaymentI from "../../Assets/Image/payment.svg";
import NewsLetterI from "../../Assets/Image/NewsLetter.svg";
import BlogI from "../../Assets/Image/Blog.svg"

const ImageHelper = {
  ISSOLogo,
  ISSOLogoPlaceholder,
  RightRoundgreen,
  AboutRightRound,
  event,
  workshop,
  seminar,
  seminarDash,
  Upcoming,
  workshopDash,
  regdeadline,
  Feature,
  DeleteCom,
  EditCom,
  Path,
  calImg,
  user1,
  facebookIcon,
  twitterIcon,
  instagramIcon,
  youtube,
  InquiryImg,
  pointer,
  benifits,
  DateCal,
  ChatIcon,
  TimerClock,
  TowerIcon,
  girlImage,
  UserCat,
  Calendar,
  CalendarPurple,
  Clock,
  ClockStudent,
  ISSOCircle,
  shining,
  EventApproved,
  EventOnGoingIcon,
  EventUpcomingIcon,
  EventCountIcon,
  EventCancelIcon,
  EventRegIcon,
  EventArchive,
  Profile,
  Circle,
  SubscribeMail,
  locationIcon,
  clockIcon,
  seatIcon,
  watchImg,
  microsoftExcel,
  rightArrowIcon,
  PdfIcon,
  microsoftWordIcon,
  leftRound,
  rightRound,
  right,
  call,
  inbox,
  map,
  FooterImgLeft,
  FooterRight,
  BlackClock,
  BellIcon,

  //SideBar Images

  NewsLetterI,
  PaymentI,
  CommunityI,
  SportsI,
  Cms,
  CertificateI,
  CategoryI,
  RoleI ,
  NewsAnInsI,
  EventsI,
  DashboardI,
  SeminarI ,
  TACI ,
  WorkshopI,
  FeedbackI,
  FAQI,
  CalendarI,
  BlogI
}

export default ImageHelper;