import { createSlice } from "@reduxjs/toolkit";

interface InitialSubscribeState {
    subscribe: any;
}

const InitialSubscribeState: InitialSubscribeState = {
    subscribe: [],
};

const subscribeSlice = createSlice({
    name: "subscribe",
    initialState: InitialSubscribeState,
    reducers: {
        addNewsSubscribe(state: any, action: any) {
            state.subscribe = action.payload;
        },
        verifyCode(state: any, action: any) {
            state.subscribe = action.payload;
        },
        sendVerificationCode(state: any, action: any) {
            state.subscribe = action.payload;
        },
    },
});

export const {
    addNewsSubscribe,
    verifyCode,
    sendVerificationCode
} = subscribeSlice.actions;

export default subscribeSlice.reducer;