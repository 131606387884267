// Created By MK

// React Imports
import React from "react";

// Third Party Imports
import CryptoJS from "crypto-js";
import moment from "moment";
import { publicIpv4 } from "public-ip";

// ** Helper
import { afternT, evenT, morT } from "../../utils/Constant";

const AppUtils = {
  // encode the string function
  decodeStr: (str: any) => {
    return CryptoJS.AES.decrypt(
      str && str && str !== null && str !== undefined && str,
      "ISSO"
    )?.toString(CryptoJS.enc.Utf8);
  },

  // decode the string function
  encodeStr: (str: string) => CryptoJS.AES.encrypt(str, "ISSO")?.toString(),

  // getLoccalStorage Data
  setLocalStorage: (key: string, val: any) => localStorage.setItem(key, val),

  // getLoccalStorage Data
  removeLocalStorageItem: (key: string) => localStorage.removeItem(key),

  // setLoccalStorage Data
  getLocalStorage: (key: string) => {
    const data = typeof window !== "undefined" && localStorage.getItem(key);

    return (
      data &&
      data !== null &&
      data !== undefined &&
      JSON.parse(AppUtils.decodeStr(data))
    );
  },

  // get user details
  getUserDtls: () => {
    const data = AppUtils.getLocalStorage("ISSO");

    return data && data !== undefined && data !== null ? data : "{}";
  },

  // apiError handle
  apiErrorHandling: (formik: any, fields: string[], error: any) => {
    let isError = true;
    if (typeof error !== "undefined" && error !== null) {
      for (let i = 0; i < fields.length; i++) {
        if (
          typeof error[fields[i]] !== "undefined" &&
          error[fields[i]] !== null &&
          error[fields[i]] !== ""
        ) {
          formik.setFieldTouched(fields[i], true, false);
          formik.setFieldError(fields[i], error[fields[i]]);
          isError = false;
        }
      }
    }

    return isError;
  },

  roundOff: (value: number, decimals: number) => {
    return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
  },

  // check any value
  checkDirectValue: (field: any) => {
    let isData = false;
    if (typeof field !== "undefined" && field !== null) {
      if (typeof field === "string" && field?.trim() !== "") {
        isData = true;
      } else if (typeof field === "boolean") {
        isData = true;
      } else if (typeof field === "number") {
        isData = true;
      } else if (typeof field === "object") {
        isData = true;
      }
    }

    return isData;
  },

  checkRefValue: (field: any) => {
    let isData = field;
    const parent = isData.current.parentNode as HTMLElement;

    if (parent) {
      const grandParent = parent.parentNode as HTMLElement;
      if (grandParent) {
        grandParent.style.position = "sticky";
        grandParent.style.right = "0px";
        grandParent.style.backgroundColor = "white";
        grandParent.style.zIndex = "1";
      }
    }

    return isData;
  },

  checkValue: (val: any) => {
    if (val === null || val === undefined) return false;

    if (typeof val === "boolean") return true;

    if (typeof val === "number") return !isNaN(val);

    if (typeof val === "string" || Array.isArray(val)) return val.length > 0;

    if (typeof val === "object") return Object.keys(val).length > 0;

    return false;
  },

  checkFieldValue: (data: any, field: any) => {
    let isData = false;
    if (
      AppUtils.checkDirectValue(data) &&
      typeof data?.[field] !== "undefined" &&
      data?.[field] !== null
    ) {
      if (typeof data?.[field] === "string" && data?.[field]?.trim() !== "") {
        isData = true;
      } else if (typeof data?.[field] === "boolean") {
        isData = true;
      } else if (typeof data?.[field] === "number") {
        isData = true;
      } else if (typeof data?.[field] === "object") {
        isData = true;
      }
    }

    return isData;
  },

  // For birthdate validation
  isValidBirthdate: (val: any, isReq: boolean) => {
    if (isReq === false && AppUtils.checkDirectValue(val) === false) {
      return true;
    }
    const value = moment(val, "MM/DD/YYYY").format("MM/DD/YYYY");
    const nDate = moment(value);
    const validDate = nDate?.isValid();
    const diff = moment().diff(value);

    const dateArr = AppUtils.checkDirectValue(val) ? val.split("/") : [];

    if (
      AppUtils.checkDirectValue(value) === true &&
      validDate &&
      diff >= 0 &&
      AppUtils.checkDirectValue(val) === true &&
      val?.length === 10 &&
      AppUtils.checkFieldValue(dateArr, "length") &&
      dateArr?.length === 3 &&
      Number.isNaN(dateArr[2]) === false &&
      Number(dateArr[2]) !== 0
    ) {
      return true;
    }

    return false;
  },
  //for mobile number setup
  mobileNumberFormate: (value: string) => {
    let mNumString = value;
    let formatted = "";
    if (typeof mNumString !== "undefined" && mNumString !== null) {
      mNumString = mNumString.replace(/[^0-9]/g, "");
      let block1 = "";
      let block2 = "";
      let block3 = "";

      //for set number formate 3-3-4
      block1 = mNumString.substring(0, 3);
      if (block1?.length === 3 && mNumString?.length > 3) {
        block1 = "(" + block1 + ")-";
      }
      block2 = mNumString.substring(3, 6);
      if (block2?.length === 3 && mNumString?.length > 6) {
        block2 = block2 + "-";
      }
      block3 = mNumString.substring(6, 10);
      if (block3?.length === 4) {
        block3 = block3 + "";
      }

      formatted = block1 + block2 + block3;
      // }
    }
    return formatted;
  },

  formatMoneyNumber: (
    nAmount: string,
    decimalCount = 2,
    decimal = ".",
    thousands = ","
  ) => {
    let amount = nAmount;
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = Number(amount) < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substring(0, j) + thousands : "") +
        i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
          Math.abs(Number(amount) - Number(i))
            .toFixed(decimalCount)
            .slice(2)
          : "")
      );
    } catch (e) {
      console.log("e : ", e);
      return "";
    }
  },

  updateNumberWithCurrenySymbolString: (
    targetValue: string,
    oldValue: string,
    update = false
  ) => {
    const currency = "$"; // Add "$" symbol as the currency
    let newTargetVal = "";
    if (targetValue !== "") {
      let mainResult = targetValue
        ?.toString()
        ?.replace(/[^\d.-]/g, "")
        ?.replace("-", "")
        ?.replace("$", "")
        ?.replace(/,/g, "");
      if (mainResult !== "" && /^\d+$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 0);
      } else if (mainResult !== "" && /^\d+\.\d{0,0}$/.test(mainResult)) {
        newTargetVal = currency + mainResult;
      } else if (mainResult !== "" && /^\d+\.\d{0,1}$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 1);
      } else if (mainResult !== "" && /^\d+\.\d{0,2}$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 2);
      } else if (mainResult !== "" && /^\d+\.\d{0,3}$/.test(mainResult)) {
        if (update) {
          newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 2);
        } else {
          newTargetVal = oldValue?.toString()?.replace("-", "");
        }
      } else if (mainResult !== "" && mainResult === ".") {
        newTargetVal =
          currency + AppUtils.formatMoneyNumber(mainResult, 0) + ".";
      } else if (mainResult.includes(".")) {
        newTargetVal = oldValue || "";
      } else if (
        mainResult === "" &&
        (targetValue === "$" || targetValue === "-$")
      ) {
        newTargetVal = mainResult;
      } else {
        if (update) {
          newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 2);
        } else {
          newTargetVal = oldValue?.toString()?.replace("-", "");
        }
      }
    } else {
      let mainResult = targetValue
        ?.toString()
        ?.replace(/[^\d.-]/g, "")
        ?.replace("$", "")
        ?.replace(/,/g, "");
      if (mainResult !== "" && /^\d+$/.test(mainResult)) {
        newTargetVal = currency + AppUtils.formatMoneyNumber(mainResult, 0);
      } else {
        newTargetVal = mainResult;
      }
    }
    return newTargetVal;
  },

  floatToPercentageString: (value: any) => {
    let cleanedValue = value.replace(/[^\d.]/g, "");
    cleanedValue = cleanedValue.replace(/(\..*)\./g, "$1");
    const match = cleanedValue.match(/^(?!00)(\d{0,2}(\.(?!00)\d{0,2})?)?$/);

    if (match) {
      if (!cleanedValue.includes("")) {
        cleanedValue = cleanedValue.replace(/(\d{2})/, "$1.");
      }
      return `${cleanedValue}`;
    } else {
      return `%`;
    }
  },

  // Create random string
  generateRandomString() {
    const result = Math.random().toString(36).substring(2, 7);
    return result;
  },

  // get current view width and height
  useContainerDimensions(divRef: any) {
    const getDimensions = () => ({
      width: divRef?.current?.offsetWidth ? divRef.current.offsetWidth : 0,
      height: divRef?.current?.offsetHeight ? divRef.current.offsetHeight : 0,
      top:
        (divRef?.current?.offsetTop ? divRef.current.offsetTop : 0) +
        (divRef?.current?.offsetHeight ? divRef.current.offsetHeight : 0),
    });

    const [dimensions, setDimensions] = React.useState({
      width: 0,
      height: 0,
      top: 0,
    });

    React.useEffect(() => {
      const handleResize = () => {
        setDimensions(getDimensions());
      };

      if (divRef?.current) {
        setDimensions(getDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [divRef]);

    return dimensions;
  },

  // check any value from arry
  checkValueFromArr(arr: any, fields: any) {
    let isValid = false;
    if (typeof arr !== "undefined" && arr !== null && arr?.length > 0) {
      let passVal = 0;
      for (let i = 0; i < fields.length; i++) {
        if (arr.includes(fields[i])) {
          passVal = passVal + 1;
        }
      }
      if (passVal === fields?.length) {
        isValid = true;
      }
    }
    return isValid;
  },

  reorderList: (
    list: Iterable<unknown> | ArrayLike<unknown>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  },

  getBedge(type: number) {
    return type === 1
      ? "primary"
      : type === 2
        ? "primary"
        : type === 3
          ? "success"
          : type === 4
            ? "danger"
            : type === 5
              ? "primary"
              : "";
  },

  getBedgeText(type: number) {
    return type === 1
      ? "Open For Registration"
      : type === 2
        ? "Ongoing"
        : type === 3
          ? "Completed"
          : type === 4
            ? "Cancel"
            : type === 5
              ? "Registration Closed"
              : "";
  },

  dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },

  // PublicIp Helper
  publicIp: null as string | null,

  fetchPublicIp: async () => {
    try {
      AppUtils.publicIp = await publicIpv4();
    } catch (error) {
      console.error("Failed to fetch public IPv4 address:", error);
      AppUtils.publicIp = ""; // Fallback to an empty string if publicIpv4() fails
    }
  },

  getPublicIp: () => AppUtils.publicIp,

  hasPermissions: (module: string) => {
    const { user_type, permissions } = AppUtils.getLocalStorage("ISSO")?.data;
    if ((user_type && user_type === 2) || user_type === 3 || user_type === 4) {
      if (permissions[module]) {
        return permissions[module] || false;
      } else {
        return false;
      }
    } else if (user_type && user_type === 1) {
      return {
        view: true,
        add: true,
        edit: true,
        delete: true,
        add_comment: true,
        edit_comment: true,
        add_reply: true,
        edit_reply: true,
        delete_comment: true,
        delete_reply: true,
        copy_question: true,
        change_faqs_status: true,
        change_news_status: true,
        view_registered_user_list: true,
        view_waiting_list: true,
        edit_permission: true,
        view_permission: true,
        edit_certificate_badge: true,
        copy_faqs: true,
      };
    } else {
      return false;
    }
  },

  generateSlug: (value: string) => {
    const slugValue = value
      .trim() // Remove spaces at the beginning and end
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with dashes
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing dashes

    return slugValue;
  },

  // set custom wishes of time
  checkTimeWishes: () => {
    const today = new Date();
    const curHr = today.getHours();
    let currentTimeW = "";

    if (curHr < 12) {
      currentTimeW = morT;
    } else if (curHr < 18) {
      currentTimeW = afternT;
    } else {
      currentTimeW = evenT;
    }
    return currentTimeW;
  },

  // hasPermission: (module: string, action: number) => {
  //   const { user_type, permissions } = AppUtils.getLocalStorage("ISSO")?.data;

  //   if (user_type && user_type === 2 || user_type === 3) {
  //     const actionMapping = ["view", "add", "edit", "delete", "copy_question", "change_faqs_status", "view_registered_user_list",
  //       "view_waiting_list", "edit_permission", "view_permission", "edit_certificate_badge"
  //     ];

  //     if (action < 0 || action >= actionMapping.length) {
  //       return false;
  //     }

  //     const actionString = actionMapping[action];

  //     if (permissions[module]) {
  //       return permissions[module][actionString] || false;
  //     } else {
  //       return false;
  //     }
  //   } else if (user_type && user_type === 1) {
  //     return {
  //       view: true,
  //       add: true,
  //       edit: true,
  //       delete: true,
  //       copy_question: true,
  //       change_faqs_status: true,
  //       view_registered_user_list: true,
  //       view_waiting_list: true,
  //       edit_permission: true,
  //       view_permission: true,
  //       edit_certificate_badge: true,
  //     };
  //   } else {
  //     return false;
  //   }
  // },

  // checkRouteMenus: (currentRoute: string) => {
  //   let finalUrl = "";
  //   const splitArr = currentRoute.split("/");

  //   if (splitArr?.length >= 2) {
  //     for (let i = 0; i < splitArr.length; i++) {
  //       if (i > 2) {
  //         break;
  //       }
  //       let passNext = true;
  //       if (passNext) {
  //         if (i > 0) {
  //           finalUrl = finalUrl + "/";
  //         }
  //         finalUrl = finalUrl + splitArr[i];
  //       }
  //     }
  //   }
  //   return finalUrl;
  // }
};

export default AppUtils;
