import { createSlice } from "@reduxjs/toolkit";

interface InitialContactUsState {
  contactUs: any;
}
const initialUsersState: InitialContactUsState = {
  contactUs: [],
};

const ContactUsSlice = createSlice({
  name: "contactUs",
  initialState: initialUsersState,
  reducers: {
    addContact(state: any, action: any) {
      state?.contactUs?.push(action.payload);
    },
    getContactDetails(state: any, action: any) {
      state.contactUs = action.payload;
    },
  },
});
export const { addContact, getContactDetails } = ContactUsSlice.actions;

export default ContactUsSlice.reducer;

export const contact_info = (state: any) => state.contactUs.contactUs;
