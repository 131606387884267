// Created By MK

import { createSlice } from "@reduxjs/toolkit";

interface InitialRolesState {
  roles: any;
  permissionList: any;
  addRole: any;
  isLoading: boolean;
  rowCount: number;
  roleUserData: [];
}
const initialRolesState: InitialRolesState = {
  roles: [],
  permissionList: [],
  addRole: [],
  isLoading: false,
  rowCount: 0,
  roleUserData: [],
};

const RolesSlice = createSlice({
  name: "roles",
  initialState: initialRolesState,
  reducers: {
    fetchRole(state: any, action: any) {
      state.roles = action.payload;
    },
    setRoleById(state: any, action: any) {
      state.roleById = action.payload;
    },
    fetchPermission(state: any, action: any) {
      state.permissionList = action.payload;
    },

    setRoleCount(state: any, action: any) {
      state.rowCount = action.payload;
    },
    fetchRoleUser(state: any, action: any) {
      state.roleUserData = action.payload;
    },

    addRole(state: any, action: any) {
      state.roles.push(action.payload);
    },

    editRole(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.roles.hasOwnProperty(userId)) {
        state.roles[userId] = { ...state.roles[userId], ...action.payload };
      }
    },

    deleteRole(state: any, action: any) {
      state.roles = state.roles.filter(
        (obj: any) => obj._id !== action?.payload?.id
      );
    },

    clearPermission(state: any) {
      const data: any = [];
      state.permissionList = data;
    },

    changeLoadingState(state: any, action: any) {
      state.isLoading = action.payload.loading;
    },
  },
});

export const {
  fetchRole,
  clearPermission,
  fetchPermission,
  addRole,
  editRole,
  deleteRole,
  changeLoadingState,
  setRoleCount,
  setRoleById,
  fetchRoleUser,
} = RolesSlice.actions;

export default RolesSlice.reducer;

export const roleData = (state: any) => state.roles.roles;
export const permissionData = (state: any) => state.roles.permissionList;
export const role_Count = (state: any) => state.roles.rowCount;

export const roleById = (state: any) => state.roles.roleById;
export const roleUserData = (state: any) => state.roles.roleUserData;
