// Created By Raj

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
    eventmainpage: any;
    upcomingEvents: any;
    footer: any;
}
const initialUsersState: InitialUsersState = {
    eventmainpage: [],
    upcomingEvents: [],
    footer: [],
};

const HomeCmsSlice = createSlice({
    name: "websetting",
    initialState: initialUsersState,
    reducers: {
        addwebSettingsData(state: any, action: any) {
            state?.eventmainpage?.push(action.payload)
        },
        getWebSettings(state: any, action: any) {
            state.eventmainpage = action.payload;
        },
        getFooter(state: any, action: any) {
            state.footer = action.payload;
        },
        getUpcomingEvents(state: any, action: any) {
            state.upcomingEvents = action.payload;
        },
        addBenefits(state: any, action: any) {
            state.eventmainpage = action.payload;
        },
        addEducation(state: any, action: any) {
            state.eventmainpage = action.payload;
        },
        addRecords(state: any, action: any) {
            state.eventmainpage = action.payload;
        },
        editBenefits(state: any, action: any) {
            state.eventmainpage.benefits = state.eventmainpage.benefits.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        editRecords(state: any, action: any) {
            state.eventmainpage.counter = state.eventmainpage.counter.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        editEducation(state: any, action: any) {
            state.eventmainpage.education = state.eventmainpage.education.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deleteBenefits(state: any, action: any) {
            state.eventmainpage.benefits = state.eventmainpage.benefits.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        deleteRecords(state: any, action: any) {
            state.eventmainpage.counter = state.eventmainpage.counter.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        deleteEducation(state: any, action: any) {
            state.eventmainpage.education = state.eventmainpage.education.filter(
                (item: any) => item._id !== action?.payload
            );
        },
    },
});

export const {
    addwebSettingsData,
    getWebSettings,
    addBenefits,
    editRecords,
    editBenefits,
    deleteBenefits,
    deleteRecords,
    addEducation,
    editEducation,
    deleteEducation,
    addRecords,
    getUpcomingEvents,
    getFooter,
}: any = HomeCmsSlice.actions;

export default HomeCmsSlice.reducer;

export const web_list = (state: any) => state.websetting.eventmainpage;

export const upcoming_Events = (state: any) => state.websetting.upcomingEvents;

export const footer_Data = (state: any) => state.websetting.footer;

