import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  certificationView: any;
  certificateList: any;
}
const initialUsersState: InitialUsersState = {
  certificationView: [],
  certificateList: [],
};
const CertificationViewSlice = createSlice({
  name: "certificationView",
  initialState: initialUsersState,
  reducers: {
    geteventList(state: any, action: any) {
      state.certificateList = action.payload;
    },
  },
});
export default CertificationViewSlice.reducer;

export const { getcertificateList }: any = CertificationViewSlice.actions;
export const certificate_list = (state: any) =>
  state.certificationView.certificationView;
