// Created By Yash l.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  gamequiz: any;
}

const InitialUsersState: InitialUsersState = {
  gamequiz: [],
};

const GameSlice = createSlice({
  name: "gamequiz",
  initialState: InitialUsersState,
  reducers: {
    gameQuizAdd(state: any, action: any) {
      state.gamequiz = action.payload;
    },
    fetchGameList(state: any, action: any) {
      state.gamequiz = action.payload;
    },
    addGame(state: any, action: any) {
      state.gamequiz = action.payload;
    },
    editGame(state: any, action: any) {
      state.gamequiz.games = state.gamequiz.games.map((item: any) => {
        if (item._id === action.payload?.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
    },
    editLeaderboard(state: any, action: any) {
      state.gamequiz.leaderboard = state.gamequiz.leaderboard.map((item: any) => {
        if (item._id === action.payload?.id) {
          return { ...item, ...action.payload };
        }
        return item;
      });
    },
    deleteGame(state: any, action: any) {
      const updatedData = state.gamequiz.games.filter((item: any) => item._id !== action.payload);
      state.gamequiz.games = updatedData;
    },
    deleteLeaderboard(state: any, action: any) {
      const updatedData = state.gamequiz.leaderboard.filter((item: any) => item._id !== action.payload);
      state.gamequiz.leaderboard = updatedData;
    },
    fetchLeaderboardList(state: any, action: any) {
      state.gamequiz = action.payload;
    },
  },
});
export const {
  gameQuizAdd, fetchGameList, editGame, addGame, deleteGame, fetchLeaderboardList, editLeaderboard, deleteLeaderboard
}: any = GameSlice.actions;
export default GameSlice.reducer;

export const game_quiz_list = (state: any) => state.gamequiz.gamequiz;