// ** Create By Raj.

// Bootstrap Imports
import {
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
} from "reactstrap";

// Formik
import { useFormik } from "formik";

// Redux
import { useDispatch } from "react-redux";
import { addverifyCode } from "../../../ReduxToolkit/Reducer/Footer/FooterAction"

// ** Image
import SubscribeMail from "../../../Assets/Image/SubscribeMail.svg"

// Style
import "./Footer.scss"

interface verificationData {
    verification_code: string;
}

const defaultValues: verificationData = {
    verification_code: ""
};

export default function Subscribeotp(props: any) {
    //Props
    const { setPopupVisible, subscribeData, isPopupVisible, toggleModal } = props;

    //Hooks
    const dispatch = useDispatch();

    // Formik
    const formik = useFormik({
        initialValues: defaultValues,
        // validationSchema: validationSchema,
        onSubmit: async (values: verificationData) => {
            const passData: any = {
                id: subscribeData,
                verification_code: values.verification_code
            };
            const res = await (addverifyCode)(
                passData,
                dispatch
            );
            if (res?.status) {
                formik.resetForm();
                setPopupVisible(false)
            } else {
            }
        }
    });

    return (
        <Modal isOpen={isPopupVisible} toggle={toggleModal} centered id="fs">
            <ModalBody className="sub-modalbody">
                <div className="subimage">
                    <img src={SubscribeMail} alt="mail" />
                </div>
                <div className="subConRight">
                    <h2 className="subscribe-text">Subscribe!</h2>
                    <p className="subscribe-con">Subscribe to our newsletter and stay updated !</p>
                    <FormGroup id="in-sub" style={{ width: "100%" }}>
                        <Label className="codeProText">Enter the code provided in your mail to proceed.</Label>
                        <Input
                            type="number"
                            className="input-sub"
                            placeholder="Enter Code"
                            value={formik.values.verification_code}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                formik.setFieldValue("verification_code", e.target.value)
                            }}
                        />
                    </FormGroup>
                    <div className="proBtnPar">
                        <button
                            type="submit"
                            className="processBtn"
                            onClick={() => formik.handleSubmit()}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
