// ** Service Imports
import apiCall from "../../../Helpers/Services";
import {
    addNewsSubscribe,
    verifyCode,
    sendVerificationCode
} from "./FooterSlice";

//CMS Management
export const addNewsSubscribeData = async (passData: any, dispatch: any) => {
    const data: any = await apiCall({
        method: "POST",
        url: "settings/addNewsSubscribe",
        data: passData,
    });

    data?.status && dispatch(addNewsSubscribe({ ...passData, _id: data?.id }))

    return data;
};

export const sendVerificationCodeData = async (passData: any, dispatch: any) => {
    const data: any = await apiCall({
        method: "POST",
        url: "settings/sendVerificationCode",
        data: passData,
        isHideTostMsg: true,
    });

    data?.status && dispatch(sendVerificationCode({ ...passData, _id: data?.id }))

    return data;
};

export const addverifyCode = async (passData: any, dispatch: any) => {
    const data: any = await apiCall({
        method: "POST",
        url: "settings/verifyCode",
        data: passData,
    });

    data?.status && dispatch(verifyCode({ ...passData, _id: data?.id }))

    return data;
};