// Created By Dhruvi V.

//api call
import apiCall from "../../../Helpers/Services";

//Slice
import {
  addTestimonial,
  editTestimonial,
  deleteTestimonial,
  setTestimonialById,
  addTestimonialmainpage,
  fetchTestimonial,
  fetchTestimonialSEO
} from "./TestimonialSlice";

export const addTestimonialmainpageData = async (
  passData: any,
  dispatch: any
) => {
  const data: any = await apiCall({
    method: "POST",
    url: "settings/webSettings",
    data: passData,
  });

  data?.status &&
    dispatch(addTestimonialmainpage({ ...passData, _id: data?.id }));

  return data;
};

export const getTestimonialList = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "testimonial/getTestimonialList",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(fetchTestimonial(data?.data));
    dispatch(fetchTestimonialSEO(data?.seo_setting));
  }
};

export const addTestimonialData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "testimonial/addTestimonial",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(addTestimonial({ ...passData, _id: data?.data._id }))

  return data;
};

export const editTestimonialData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "testimonial/editTestimonial",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(editTestimonial(passData));

  return data;
};

export const findTestimonialById = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "testimonial/findTestimonialById",
    data: passData,
    isHideTostMsg: true,
  });

  data?.status && dispatch(setTestimonialById(data?.data));

  return data;
};
export const deleteTestimonialData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "testimonial/deleteTestimonial",
    data: passData,
  });

  data?.status && dispatch(deleteTestimonial(passData?.id));

  return data;
};

export const changeTestimonialPosition = async (passData: any) => {
  const data = await apiCall({
    method: "POST",
    url: "testimonial/changeTestimonialPosition",
    data: { id: passData?.map((item: any) => item?._id) },
  });

  if (data?.status) {
    return data?.data;
  }
};

export const fetchTestimonialList = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "testimonial/fetchTestimonialList",
    data: passData,
    isHideTostMsg: true,
  });

  if (data?.status) {
    dispatch(fetchTestimonial(data?.data));
  }
};
