// React Imports
import { useEffect } from "react";

// Third Party Imports
import { ToastContainer } from "react-toastify";

// Routes Imports
import RouterData from "./Routes";
import socket from './Config/Socket';

const redirectedURL: any = {
  event: 'events-and-compititions',
  seminar: 'seminars',
  workshop: 'workshops',
  UserEvent: 'events-and-compititions'
};

const App = () => {

  useEffect(() => {
    // Check for Notification API support
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notifications')
      return;
    }

    // Request notification permission on component mount
    if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        if (permission !== 'granted') {
          console.log('Notification permission denied')
        }
      });
    }
  }, []);

  useEffect(() => {
    const onNotificationArrived = (value: any) => {
      const title = value?.title ?? '';
      const options = {
        body: value?.message ?? '',
        icon: './Assets/Image/ISSO logo.svg',
        tag: value?.reference_id,
        data: {
          url: value?.module === 'Inquiry' ? `https://isso.tjcg.in/inquiry/a/${value?.reference_id}` : `https://isso.tjcg.in/${redirectedURL[value?.module]}`
        }
      };

      if (Notification.permission === 'granted') {
        const notification = new Notification(title, options);
        notification.onclick = (event) => {
          event.preventDefault();
          window.open(notification.data.url, '_blank');
        };
      } else {
        console.error('Notification permission not granted');
      }
    };

    socket.on("notificationArrived", onNotificationArrived);

    // Cleanup Function
    return () => {
      socket.off("notificationArrived", onNotificationArrived);
    };
  }, []);

  return (
    <>
      <RouterData />
      <ToastContainer />
    </>
  );
}

export default App;
