// Created By Raj.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
  Event_ById: any;
  events: any;
  userEvents: any;
  eventUserlist_rowCount: number;
  spUserEvents: any[];
  spRecordCount: number;
  hasMore: boolean;
  eventCounts: any;
  eventApprovalSta: any;
  eventApprovalStatusCount: any;
}
const usereventState: InitialUsersState = {
  Event_ById: {},
  events: [],
  userEvents: [],
  eventUserlist_rowCount: 0,
  hasMore: true,
  spUserEvents: [],
  eventCounts: [],
  eventApprovalSta: [],
  eventApprovalStatusCount: [],
  spRecordCount: 0,
};

const UserEventSlice = createSlice({
  name: "userevent",
  initialState: usereventState,
  reducers: {
    fetchEvents(state: any, action: any) {
      const { isSearch } = action.payload;
      if (isSearch) {
        state.events = action.payload.data;
      } else {
        state.events = action.payload.data;
      }
    },
    fetchEventsForCal(state: any, action: any) {
      state.events = action.payload;
    },
    // fetchEventsApprovStatus(state: any, action: any) {
    //   state.eventApprovalSta = action.payload;
    // },
    fetchEventStatus(state: any, action: any) {
      state.eventApprovalStatusCount = action.payload;
    },
    EventCounting(state: any, action: any) {
      state.eventCounts = action.payload;
    },
    fetchUserEvents(state: any, action: any) {
      const { isSearch } = action.payload;
      if (isSearch) {
        state.userEvents = action.payload.data;
      } else {
        state.userEvents = action.payload.data;
      }
    },
    setEventUserlistRowCount(state: any, action: any) {
      state.eventUserlist_rowCount = action.payload;
    },
    addEvent(state: any, action: any) {
      state?.userEvents?.unshift(action.payload);
    },
    dragCalData(state: any, action: any) {
      state?.event?.push(action.payload);
    },
    deleteEvent(state: any, action: any) {
      state.userEvents = state.userEvents.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    cancelEvent(state: any, action: any) {
      state.userEvents = state.events.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    editEvent(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.userEvents.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.userEvents[objIndex] = {
          ...state.userEvents[objIndex],
          ...updatedData,
        };
      }
    },
    setUsereventById(state: any, action: any) {
      state.UserEvent_ById = action.payload;
    },
    setHasMore(state: any, action: any) {
      state.hasMore = action.payload;
    },
    clearEvents(state: any) {
      state.events = [];
    },
    fetchSPUserEvents(state: any, action: any) {
      const { isSearch } = action.payload;
      if (isSearch) {
        state.spUserEvents = action.payload.data;
      } else {
        state.spUserEvents = action.payload.data;
      }
    },
    setSPRecordCount(state: any, action: any) {
      state.spRecordCount = action.payload;
    },
    updateSPEvents(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.spUserEvents.findIndex(
        (obj: any) => obj._id === updatedData?._id
      );

      if (objIndex !== -1) {
        state.spUserEvents[objIndex] = {
          ...state.spUserEvents[objIndex],
          ...updatedData,
        };
      }
    },
  },
});

export const {
  clearEvents,
  setHasMore,
  addEvent,
  editEvent,
  setUsereventById,
  fetchEvents,
  fetchEventsForCal,
  // fetchEventsApprovStatus,
  fetchEventStatus,
  EventCounting,
  fetchUserEvents,
  cancelEvent,
  setEventUserlistRowCount,
  deleteEvent,
  dragCalData,
  fetchSPUserEvents,
  setSPRecordCount,
  updateSPEvents,
}: any = UserEventSlice.actions;

export default UserEventSlice.reducer;

export const eventList = (state: any) => state.userevent.events;

export const allEventCount = (state: any) => state.userevent.eventCounts;

export const userEvents = (state: any) => state.userevent.userEvents;

export const eventUserlist_rowCount = (state: any) =>
  state.userevent.eventUserlist_rowCount;

export const UserEvent_ById = (state: any) => state.userevent.UserEvent_ById;

export const hasMore = (state: any) => state.userevent.hasMore;

export const sp_user_events = (state: any) => state.userevent.spUserEvents;

export const sp_record_count = (state: any) => state.userevent.spRecordCount;

export const event_approved_status_list = (state: any) => state.userevent.eventApprovalSta;

export const event_approved_status_counts = (state: any) => state.userevent.eventApprovalStatusCount;