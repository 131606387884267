// Created By Raj

import { createSlice } from "@reduxjs/toolkit";

interface initialPastEventState {
    eventCategory: any;
    eventPastList: any;
    photoGallery: any;
    eventdropdownList: any;
    userdropdownlist: any;
    pastEventById: any;
    archivecatById: any;
    cat_rowCount: number;
}
const initialPastEventState: initialPastEventState = {
    eventCategory: [],
    eventPastList: [],
    photoGallery: [],
    eventdropdownList: [],
    userdropdownlist: [],
    pastEventById: {},
    archivecatById: {},
    cat_rowCount: 0,
};

const PastEventSlice = createSlice({
    name: "eventCategory",
    initialState: initialPastEventState,
    reducers: {
        addCategory(state: any, action: any) {
            state?.eventCategory?.push(action.payload)
        },
        categoryList(state: any, action: any) {
            state.eventCategory = action.payload;
        },
        setCatRowCount(state: any, action: any) {
            state.cat_rowCount = action.payload;
        },
        pastEventdropdownList(state: any, action: any) {
            state.eventdropdownList = action.payload;
        },
        pastEventuserdropdownList(state: any, action: any) {
            state.userdropdownlist = action.payload;
        },
        editCategory(state: any, action: any) {
            state.eventCategory = state.eventCategory.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deleteCategory(state: any, action: any) {
            state.eventCategory = state.eventCategory.filter(
                (item: any) => item._id !== action?.payload
            );
        },

        addPastEvents(state: any, action: any) {
            state?.eventPastList?.push(action.payload)
        },
        pastEventsList(state: any, action: any) {
            state.eventPastList = action.payload;
        },
        editPastEvents(state: any, action: any) {
            state.eventPastList = state.eventPastList.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deletePastEvents(state: any, action: any) {
            state.eventPastList = state.eventPastList.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        setPastEventById(state: any, action: any) {
            state.pastEventById = action.payload;
        },
        setArchivecatById(state: any, action: any) {
            state.archivecatById = action.payload;
        },
        clearPastEventReg: (state: any) => {
            state.pastEventById = {};
        },
        clearArchivecat: (state: any) => {
            state.archivecatById = {};
        },
        addPhotoGallery(state: any, action: any) {
            state?.photoGallery?.push(action.payload)
        },
        getPhotoGalleryList(state: any, action: any) {
            state.photoGallery = action.payload;
        },
        deletePhotoGallery(state: any, action: any) {
            state.photoGallery = state.photoGallery.filter(
                (item: any) => item._id !== action?.payload
            );
        },
    },
});

export const {
    addCategory,
    categoryList,
    setCatRowCount,
    editCategory,
    deleteCategory,
    addPastEvents,
    pastEventsList,
    editPastEvents,
    deletePastEvents,
    addPhotoGallery,
    getPhotoGalleryList,
    deletePhotoGallery,
    pastEventdropdownList,
    pastEventuserdropdownList,
    setPastEventById,
    setArchivecatById,
    clearPastEventReg,
    clearArchivecat,
}: any = PastEventSlice.actions;

export default PastEventSlice.reducer;

export const eCat_list = (state: any) => state.eventCategory.eventCategory;

export const cat_rowCount = (state: any) => state.eventCategory.cat_rowCount;

export const ePast_list = (state: any) => state.eventCategory.eventPastList;

export const gallery_list = (state: any) => state.eventCategory.photoGallery;

export const eventdd_list = (state: any) => state.eventCategory.eventdropdownList;

export const userdd_list = (state: any) => state.eventCategory.userdropdownlist;

export const Event_ById = (state: any) => state.eventCategory.pastEventById;

export const EventCat_ById = (state: any) => state.eventCategory.archivecatById;
