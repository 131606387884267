// Created By Yash l.

import { createSlice } from "@reduxjs/toolkit";

interface initialAboutUsState {
    aboutUs: any;
    aboutUsData: any;
    teacherTeam: any;
    teacherList: any;
    history: any;
    team: any;
    teamLeader: any;
}
const initialAboutUsState: initialAboutUsState = {
    aboutUs: [],
    aboutUsData: [],
    teacherList: [],
    teacherTeam: [],
    history: [],
    team: [],
    teamLeader: []
};

const HomeCmsSlice = createSlice({
    name: "aboutData",
    initialState: initialAboutUsState,
    reducers: {
        aboutusSettings(state: any, action: any) {
            state?.aboutUs?.push(action.payload)
        },
        getAboutusSettings(state: any, action: any) {
            state.aboutUs = action.payload;
        },
        getAboutUsList(state: any, action: any) {
            state.aboutUsData = action.payload;
        },
        getTeacherTeam(state: any, action: any) {
            state.teacherTeam = action.payload;
        },
        getTeacherList(state: any, action: any) {
            state.teacherList = action.payload;
        },
        editHistory(state: any, action: any) {
            state.aboutUs.history = state?.aboutUs?.history.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deleteHistory(state: any, action: any) {
            const updatedHistory = state.aboutUs.history.filter((item: any) => item._id !== action.payload);
            state.aboutUs.history = updatedHistory;
        },
        addTeamMember(state: any, action: any) {
            state?.team?.push(action.payload)
        },
        editTeamMember(state: any, action: any) {
            state.team = state.team.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deleteTeamMember(state: any, action: any) {
            state.team = state.team.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        teammemberList(state: any, action: any) {
            state.team = action.payload;
        },
        addLeader(state: any, action: any) {
            state?.teamLeader?.push(action.payload)
        },
        editLeader(state: any, action: any) {
            state.teamLeader = state.teamLeader.map((item: any) => {
                if (item._id === action.payload?.id) {
                    return { ...item, ...action.payload }
                }

                return item
            })
        },
        deleteLeader(state: any, action: any) {
            state.teamLeader = state.teamLeader.filter(
                (item: any) => item._id !== action?.payload
            );
        },
        teamLeaderList(state: any, action: any) {
            state.teamLeader = action.payload;
        },
    },
});

export const {
    aboutusSettings,
    getAboutusSettings,
    getAboutUsList,
    getTeacherTeam,
    getTeacherList,
    addHistory,
    editHistory,
    deleteHistory,
    addTeamMember,
    editTeamMember,
    deleteTeamMember,
    deleteLeader,
    editLeader,
    addLeader,
    teamLeaderList,
    teammemberList
}: any = HomeCmsSlice.actions;

export default HomeCmsSlice.reducer;

export const webAbout_list = (state: any) => state.aboutData.aboutUs;

export const aboutus_list = (state: any) => state.aboutData.aboutUsData;

export const teacherTeam_list = (state: any) => state.aboutData.teacherTeam;

export const teacher_list = (state: any) => state.aboutData.teacherList;

export const team_list = (state: any) => state.aboutData.team;

export const teamLe_list = (state: any) => state.aboutData.teamLeader;

export const history_list = (state: any) => state.aboutData.history;

