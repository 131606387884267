import { createSlice } from "@reduxjs/toolkit";

interface InitialCategoryState {
  category: any;
  planCatList: any;
  cat_rowCount: number;
  categoryById: any;
  subCategoryById: any;
  isLoading: boolean;
  permission: any;
  newuserdata : any
}

const InitialCategoryState: InitialCategoryState = {
  category: [],
  isLoading: true,
  planCatList: [],
  cat_rowCount: 0,
  categoryById: {},
  subCategoryById: {},
  permission: {},
  newuserdata:{},
};

const categorySlice = createSlice({
  name: "category",
  initialState: InitialCategoryState,
  reducers: {
    fetchCategory(state: any, action: any) {
      state.category = action.payload;
    },
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
    addCategory(state: any, action: any) {
      if (state?.category) {
        state.category.unshift(action.payload);
        if (state.category.length > 10) {
          state.category.pop();
        }
      } else {
        state.category = action.payload;
      }
      state.cat_rowCount = Number(state.cat_rowCount) + 1;
    },
    setCatRowCount(state: any, action: any) {
      state.cat_rowCount = action.payload;
    },
    deleteCategory(state: any, action: any) {
      state.category = state.category.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    editCategory(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.category.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.category[objIndex] = {
          ...state.category[objIndex],
          ...updatedData,
        };
      }
    },
    fetchlevel(state: any, action: any) {
      state.level = action.payload;
    },
    addLevel(state: any, action: any) {
      state?.level?.push(action.payload);
      state.total = Number(state.total) + 1;
    },
    editLevel(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.level.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.level[objIndex] = {
          ...state.level[objIndex],
          ...updatedData,
        };
      }
    },
    editLevelColor(state: any, action: any) {
      const updatedData = action.payload;
      const objIndex = state.level.findIndex(
        (obj: any) => obj._id === updatedData?.id
      );

      if (objIndex !== -1) {
        state.level[objIndex] = {
          ...state.level[objIndex],
          ...updatedData,
        };
      }
    },
    deleteLevel(state: any, action: any) {
      state.level = state.level.filter(
        (item: any) => item._id !== action?.payload
      );
    },
    setCategoryById(state: any, action: any) {
      state.categoryById = action.payload;
    },
    setSubCategoryById(state: any, action: any) {
      state.subCategoryById = action.payload;
    },
    clearcat: (state: any) => {
      state.categoryById = {};
    },
    clearSubcat: (state: any) => {
      state.subCategoryById = {};
    },
    getCatListData(state: any, action: any) {
      state.planCatList = action.payload;
    },
    getLevelPermission(state: any, action: any) {
      state.permission = action.payload;
    },
    updateLevelPermission(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.permission.hasOwnProperty(userId)) {
        state.permission[userId] = { ...state.permission[userId], ...action.payload };
      }
    },
    changeLevelPromoStatus(state: any, action: any) {
      const userId = action.payload?.id;
      if (userId && state.permission.hasOwnProperty(userId)) {
        state.permission[userId] = { ...state.permission[userId], ...action.payload };
      }
    },
    newUserData(state: any, action: any) {
      state.newuserdata = action.payload;
    },
    
  },
});

export const {
  fetchCategory,
  setLoading,
  deleteCategory,
  addCategory,
  addLevel,
  editCategory,
  fetchlevel,
  editLevel,
  editLevelColor,
  deleteLevel,
  setCatRowCount,
  setCategoryById,
  setSubCategoryById,
  clearcat,
  clearSubcat,
  getCatListData,
  getLevelPermission,
  updateLevelPermission,
  changeLevelPromoStatus,
  newUserData,
}: any = categorySlice.actions;

export default categorySlice.reducer;

export const category_list = (state: any) => state.category.category;

export const categoryLoading = (state: any) => state.category.category;

export const level_list = (state: any) => state.category.level;

export const plan_cat_list = (state: any) => state.category.planCatList;

export const cat_rowCount = (state: any) => state.category.cat_rowCount;

export const categoryById = (state: any) => state.category.categoryById;

export const subCategoryById = (state: any) => state.category.subCategoryById;

export const getLevelPer_ById = (state: any) => state.category.permission;

export const new_user_data = (state: any) => state.category.newuserdata;

