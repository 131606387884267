// Created By Yash l.

import { createSlice } from "@reduxjs/toolkit";

interface InitialUsersState {
    popularEvents: any;
    fetchIsPopularEvents: any;
    spUserSeminar: any[];
}
const initialUsersState: InitialUsersState = {
    popularEvents: [],
    fetchIsPopularEvents: [],
    spUserSeminar: [],
};

const PopularEventsSlice = createSlice({
    name: "popularEvents",
    initialState: initialUsersState,
    reducers: {
        addPopularData(state: any, action: any) {
            state?.popularEvents?.push(action.payload)
        },
        popularEveLists(state: any, action: any) {
            state.popularEvents = action.payload;
        },
        fetchPopularEvents(state: any, action: any) {
            state.fetchIsPopularEvents = action.payload;
        },
        updateEvent(state: any, action: any) {
            const updatedData = action.payload;
            const objIndex = state.fetchIsPopularEvents.findIndex(
                (obj: any) => obj._id === updatedData?._id
            );

            if (objIndex !== -1) {
                state.fetchIsPopularEvents[objIndex] = {
                    ...state.fetchIsPopularEvents[objIndex],
                    ...updatedData,
                };
            }
        },
    },
});

export const {
    addPopularData, popularEveLists, fetchPopularEvents, updateEvent
}: any = PopularEventsSlice.actions;

export default PopularEventsSlice.reducer;

export const popularEvents_List = (state: any) => state.popularEvents.popularEvents;

export const isPopularEvents_List = (state: any) => state.popularEvents.fetchIsPopularEvents;

export const event_User = (state: any) => state.popularEvents.fetchIsPopularEvents;




