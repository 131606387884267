import { configureStore } from "@reduxjs/toolkit";
import LayoutSlice from "./Reducer/LayoutSlice";
import ThemeCustomizerSlice from "./Reducer/ThemeCustomizerSlice";
import BookmarkHeaderSlice from "./Reducer/BookmarkHeaderSlice";

// New
import AuthSlice from "./Reducer/Auth/AuthSlice";
import UsersSlice from "./Reducer/Users/UsersSlice";
import categorySlice from "./Reducer/Category/categorySlice";
import RolesSlice from "./Reducer/Roles/RolesSlice";
import formbuilderSlice from "./Reducer/FormBuilder/formbuilderSlice";
import categoryListSlice from "./Reducer/Plan/planSlice";
import EventSlice from "./Reducer/Event/EventSlice";
import FAQsSlice from "./Reducer/FAQs/FAQsSlice";
import TemplateSlice from "./Reducer/Template/TemplateSlice";
import BadgesSlice from "./Reducer/Badges/BadgesSlice";
import termsAndConditionSlice from "./Reducer/TermsAndConditions/TermsAndConditionsSlice";
import WorkShopSlice from "./Reducer/Workshop/WorkshopSlice";
import InteractiveSlice from "./Reducer/InteractiveZone/InteractiveZoneSlice";
import newsCateListSlice from "./Reducer/NewsAnInsights/NewsAnInsightsSlice";
import newsListSlice from "./Reducer/NewsAnInsightsList/NewsAnInsightsListSlice";
import CompetitionSlice from "./Reducer/Competitions/CompetitionsSlice";
import SeminarSlice from "./Reducer/Seminar/SeminarSlice";
import UserEventsSlice from "./Reducer/UserEvent/UserEventsSlice";
import CertiSlice from "./Reducer/UserCertificate/UserCertificateSlice";
import CertificateApprovalSlice from "./Reducer/CertificateApproval/CertificateApprovalSlice";
import PrivacyPolicySlice from "./Reducer/PrivacyPolicy/PrivacyPolicySlice";
import SportTypeSlice from "./Reducer/Sport_Type/SportTypeSlice";
import BlogsSlice from "./Reducer/Blogs/BlogsSlice";
import AssociatedSchoolSlice from "./Reducer/AssociatedSchool/AssociatedSchoolSlice";
import EventregSlice from "./Reducer/EventRegistration/EventregSlice";
import FeedBackSlice from "./Reducer/FeedBack/FeedBackSlice";
import ContactUsSlice from "./Reducer/ContectUs/ContectUsSlice";
import InquirySlice from "./Reducer/Inquiry/InquirySlice";
import HomeCmsSlice from "./Reducer/HomePage/HomePageSlice";
import MemberShipSlice from "./Reducer/MemberShip/MemberShipSlice";
import AboutUsSlice from "./Reducer/AboutsUs/AboutUsSlice";
import CertificationViewSlice from "./Reducer/CertificationView/CertificationViewSlice";
import PastEventSlice from "./Reducer/PastEvent/PastEventSlice";
import TestimonialSlice from "./Reducer/Testimonial/TestimonialSlice";
import VirtualSlice from "./Reducer/VirtualChallanges/VirtualChalangeSlice";
import CalenderSlice from "./Reducer/Calender/CalenderSlice";
import CommunitySlice from "./Reducer/Community/CommunitySlice";
import CommunityHomeSlice from "./Reducer/Community_Home/HomeCommunitySlice";
import DashboardSlice from "./Reducer/Dashboard/DashboardSlice";
import GameAnQuizzesSlice from "./Reducer/GamesAnQuizzes/GameAnQuizzesSlice";
import PopularEventsSlice from "./Reducer/PopularEvents/PopularEventsSlice";
import PaymentSlice from "./Reducer/Payment/PaymentSlice";
import PromotionSlice from "./Reducer/Promotions/PromotionSlice";
import PrefixSlice from "./Reducer/PrefixManagement/PrefixManSlice";
import SEOsettingSlice from "./Reducer/SEO/SEOsettingSlice";
import NewsLetterSlice from "./Reducer/NewsLetter/NewsLetterSlice";

const store = configureStore({
  reducer: {
    layout: LayoutSlice,
    themeCustomizer: ThemeCustomizerSlice,
    bookmarkHeader: BookmarkHeaderSlice,

    // New
    auth: AuthSlice,
    users: UsersSlice,
    category: categorySlice,
    roles: RolesSlice,
    formBuilder: formbuilderSlice,
    categoryList: categoryListSlice,
    event: EventSlice,
    sportType: SportTypeSlice,
    faqs: FAQsSlice,
    seminar: SeminarSlice,
    template: TemplateSlice,
    badges: BadgesSlice,
    tandc: termsAndConditionSlice,
    privacyPolicy: PrivacyPolicySlice,
    workshop: WorkShopSlice,
    zone: InteractiveSlice,
    newsCateList: newsCateListSlice,
    newsList: newsListSlice,
    userevent: UserEventsSlice,
    competition: CompetitionSlice,
    userCertificate: CertiSlice,
    certificateApproval: CertificateApprovalSlice,
    blogs: BlogsSlice,
    school: AssociatedSchoolSlice,
    eventReg: EventregSlice,
    feedback: FeedBackSlice,
    contactUs: ContactUsSlice,
    inquiry: InquirySlice,
    newLetter: NewsLetterSlice,
    websetting: HomeCmsSlice,
    memberShip: MemberShipSlice,
    aboutData: AboutUsSlice,
    certificationView: CertificationViewSlice,
    eventCategory: PastEventSlice,
    testimonial: TestimonialSlice,
    virtual: VirtualSlice,
    calender: CalenderSlice,
    community: CommunitySlice,
    communityHome: CommunityHomeSlice,
    dashboard: DashboardSlice,
    gamequiz: GameAnQuizzesSlice,
    popularEvents: PopularEventsSlice,
    payment: PaymentSlice,
    promotions: PromotionSlice,
    prefix: PrefixSlice,
    SEOsetting: SEOsettingSlice,
  },
},
);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
