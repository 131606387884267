// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

interface InitialAuthState {
  SEOsettingData: any;
  isLoading: boolean;
}

const initialAuthState: InitialAuthState = {
  SEOsettingData: {},
  isLoading: false,
};

const SEOsettingSlice = createSlice({
  name: "SEOsetting",
  initialState: initialAuthState,
  reducers: {
    getSEOsettingData(state: any, action: any) {
      state.SEOsettingData = action.payload;
    },
    setLoading(state: any, action: any) {
      state.isLoading = action.payload;
    },
  },
});

export const { getSEOsettingData, setLoading }: any = SEOsettingSlice.actions;

export default SEOsettingSlice.reducer;

export const SEO_setting_data = (state: any) => state.SEOsetting.SEOsettingData;

export const is_loading = (state: any) => state.SEOsetting.isLoading;
