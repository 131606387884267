// Created By MK

// ** Service Imports
import apiCall from "../../../Helpers/Services";
import {
  addSchool, fetchSchool, setSchoolRowCount, setSchoolById, editSchool, deleteSchool, fetchSchoolCMS
} from "./AssociatedSchoolSlice";

export const addSchoolData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "school/addSchool",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(addSchool({ ...passData, _id: data?.id }));

  return data;
};

export const schoolListData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "school/schoolList",
    data: passData,
    isHideTostMsg: true,
  });

  data?.status && dispatch(fetchSchool(data?.data));
  dispatch(setSchoolRowCount(data?.record_count));

  return data;
};

export const schoolFindById = async (passData: any, dispatch: any) => {
  const data = await apiCall({
    method: "POST",
    url: "school/findSchoolById",
    data: passData,
    isHideTostMsg: true,
  });

  data?.status && dispatch(setSchoolById(data?.data));

  return data;
};

export const editSchoolData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "school/editSchool",
    data: passData,
    isMultipart: true,
  });

  data?.status && dispatch(editSchool(passData));

  return data;
};

export const deleteSchoolData = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "school/deleteSchool",
    data: passData,
  });

  data?.status && dispatch(deleteSchool(passData?.id));

  return data;
};

//CMS Management
export const fetchSchoolList = async (passData: any, dispatch: any) => {
  const data: any = await apiCall({
    method: "POST",
    url: "school/fetchSchoolList",
    data: passData,
    isHideTostMsg: true,
  });

  data?.status && dispatch(fetchSchoolCMS(data?.data));

  return data;
};
